import TeamMembers from './TeamMembers';
import Team from './Team';
import Kartik_Ramachandani from '../../imgs/leads2022/Kartik_Ramachandani.jpg';
import Viveena_Rathi from '../../imgs/leads2022/Viveena_Rathi.jpg';
import Aditya_Chaturvedi from '../../imgs/leads2022/Aditya_Chaturvedi.jpg';
import Param_Radadiya from '../../imgs/leads2022/Param_Radadiya.jpg';
import Parth_Parmar from '../../imgs/leads2022/Parth_Parmar.jpeg';
import Aayush_Solanki from '../../imgs/leads2022/Aayush_Solanki.jpg';
import Jinal_Raval from '../../imgs/leads2022/Jinal_Raval.jpg';
import Visva_Rami from '../../imgs/leads2022/Visva_Rami.jpg'
import Divyesh_Vyas from '../../imgs/leads2022/Divyesh_Vyas.JPG'
import Bhargav_Dhimar from '../../imgs/leads2022/Bhargav_Dhimar.jpg'
import Keval_Bhavsar from '../../imgs/leads2022/Keval_Bhavsar.jpeg'
import Tabrez_Ansari from '../../imgs/leads2022/Tabrez_Ansari.jpg'
import Zeel_Parekh from '../../imgs/leads2022/Zeel_Parekh.png'


import Kunj_Dabhi from '../../imgs/Developers/Kunj_Dabhi.jpg';
import Dhruva_Patel from '../../imgs/Developers/Dhruva_Patel.jpg';
import Jay_Prajapati from '../../imgs/Developers/Jay_Prajapati.png';
import Shlok_Jadeja from '../../imgs/Developers/Shlok_Jadeja.jpg'



const Team2022 = () => {


    const ClubMembers2022 = [
        // President
        {name:'Parth Parmar' , role:'President', email:'mailto:parth.parmar30803@gmail.com' , desc:'Hey, I\'m Param Radadiya!! I\'m a quick learner and a DIY person :p I like to conduct events and interact with people. You can find me with my headphones b\'cuz I love to listen to the songs 🎧... I\'m a leader, who is creating more leaders : \)', photo:Parth_Parmar},
        // Vice President
        {name:'Aayush Solanki' , role:'Vice President', email:'mailto:poojajranpara15@gmail.com' , desc:'Hello World! I am Pooja Ranpara. Just an ordinary person with extraordinary dreams. My whole world is surrounded just by coding and debugging. Voodoo Programming!', photo:Aayush_Solanki},
        // Secretary
        {name:'Viveena Rathi' , role:'Secretary', email:'mailto:kryolitimaniac@gmail.com' , desc:'Hi! This is Keyur, a research oriented programming enthusiast who loves to learn new things. I have a never give up attitude which helps me handle complex problems. My key interests include deep learning, data analytics and problem solving. I love to help people with academics and projects.', photo:Viveena_Rathi},
        // Senior
        {name:'Param Radaduya' , role:'Senior Student Chair', email:'mailto:nigamrrpatel@gmail.com' , desc:'', photo:Param_Radadiya},
        // Senior
        {name:'Divyesh Vyas' , role:'Senior Student Chair', email:'mailto:akulupadhyay19@gmail.com' , desc:'', photo: Divyesh_Vyas},
        // Senior
        {name:'Bhargav Dhimar' , role:'Senior Student Chair', email:'mailto:akulupadhyay19@gmail.com' , desc:'', photo: Bhargav_Dhimar},
        // Documentation Head
        {name:'Dhruva Patel' ,role:'Documentation Head' , email:'' , desc:'Hello, I am Bhakti Trivedi, a web developer, and competitive programming enthusiast. I like to learn new skills and explore unique algorithms. I am a part of club IDE and CodeChef college chapter at GECGn, enjoying helping and guiding peers.', photo:Dhruva_Patel},
        // Event Head
        {name:'Jinal Raval' , role:'Event Head', email:'mailto:jitiya66@gmail.com'   , desc:'Hello, I\'m Shubham Jitiya, a passionate android developer. I like to teach & guide my peers. I have published 4 android apps on play store, built in java, having 1800+ downloads with positive ratings of 4.9. My other app [GOD - Goal of the day] developed during ASJ is featured by Google. Currently, exploring kotlin and flutter.', photo:Jinal_Raval},
        // Android Development Lead
        {name:'Vishva Rami' , role:'Android Development Lead', email:'mailto:pokarhiren7@gmail.com' , desc:'', photo: Visva_Rami},
        // Web Development Lead
        {name:'Shlok Jadeja' , role:'Web Development Lead', email:'mailto:shlokjadeja177@gmail.com' , desc:'', photo:Shlok_Jadeja},
        // Start Up Lead
        {name:'Aditya Chaturvedi' , role:'Start Up Lead', email:'mailto:nareshkumarpurohit7890@gmail.com ' , desc:'Hey I\'m Purohit Naresh . I love technology. I always enjoy learning new things and I\'m passionate  about solving real-world problem with my vision correction and building a revolutionary solution.', photo: Aditya_Chaturvedi},
        // AI/ML Lead
        {name:'Jay Prajapti' , role:'AI/ML Lead', email:'mailto:er.krutikpatel31@gmail.com' , desc:'', photo:Jay_Prajapati},
        // CP Lead
        {name:'Kartik Ramchandani' , role:'CP Group Lead', email:'mailto:divyeshvyas562@gmail.com' , desc:'Hello, This is Divyesh Vyas! ✨ A web developer and coding enthusiast.I Like to play with Bits and I\'m Flexible to switch between new software and technologies.  : \)', photo:Kartik_Ramachandani},
        // IOT Lead
        {name:'Keval Bhavsar' , role:'IOT Lead', email:'mailto:divyeshvyas562@gmail.com' , desc:'Hello, This is Divyesh Vyas! ✨ A web developer and coding enthusiast.I Like to play with Bits and I\'m Flexible to switch between new software and technologies.  : \)', photo: Keval_Bhavsar},
        // IOT Lead
        {name:'Tabrez Ansari' , role:'IOT Lead', email:'mailto:divyeshvyas562@gmail.com' , desc:'Hello, This is Divyesh Vyas! ✨ A web developer and coding enthusiast.I Like to play with Bits and I\'m Flexible to switch between new software and technologies.  : \)', photo: Tabrez_Ansari},
        // Design Lead
        {name:'Zeel Parekh' , role:'Design Team Lead', email:'mailto:divyeshvyas562@gmail.com' , desc:'Hello, This is Divyesh Vyas! ✨ A web developer and coding enthusiast.I Like to play with Bits and I\'m Flexible to switch between new software and technologies.  : \)', photo: Zeel_Parekh},

    ];



    const Developers = [
        {name:'Kunj Dabhi' , role:'Developer' , email:'mailto:kunjdabhi0808@gmail.com', desc:'Hey 👋! This is Kunj, an aspiring Web Developer. Currently Exploring world of Web technologies and learning something new everyday.', photo:Kunj_Dabhi},

        {name:'Shlok Jadeja' , role:'Developer', email:'mailto:shlokjadeja177@gmail.com', desc:'Hi!😁 Shlok Jadeja signing in on a journey of web dev and diving into the world of computers. I am the glue to the team, well not really, nobody listens to me here. Yet, when in need - I love to work together with them and tackle problems while making a change. Call me a leader yet? Shlok Jadeja out.', photo:Shlok_Jadeja},

        {name:'Dhruva Patel' , role:'Developer', email:'mailto:pateldhruva751@gmail.com', desc:'Greetings!✨ Dhruva Patel buzzing with tech this side. A tech enthusiast who loves exploring various fields but web devlopment has always grabbed my attention. I enjoy interacting with people and making tasks interesting is my side hustle, because of course \'nobody likes boring\', right!? On a journey to learn with so much fun, cannot stop me now! ', photo:Dhruva_Patel},

        {name:'Jay Prajapati' , role:'Developer', email:'mailto:jay54prajapati@gmail.com', desc:'Hi! I\'m Jay. I enjoy designing and creating apps & websites. A machine Learning enthusiast keen on expressing, learning, and open to new opportunities. ', photo:Jay_Prajapati},

    ]

    return (
        <>
            <Team />
            <TeamMembers title={'Club leads'} members={ClubMembers2022} />
            <TeamMembers title={'Developers'} members={Developers} />

        </>


     );

    }

export default Team2022;