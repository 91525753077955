import React from 'react'
import { useState } from 'react';

import { ref, uploadBytes } from "firebase/storage";
import ScrollToTop from '../../Components/ScrollToTop';

export default function Newevent() {


  const [user, setUser] = useState({

    name: "",
    host: "",
    mode: "",
    date: "",
    club: "",
    thumbnail: "",
    isCompleted: "",
    Reportlink: ""
  });

  const [login , setLogin] = useState(0);

  const [admin, setadmin] = useState({
    email: "",
    password: "",
  });

  let Email  , Value;
  const getloginData = (event) => {
    Email = event.target.name;
    Value = event.target.value;
    setadmin({ ...admin, [Email]: Value })
  }

 const checkpassword  = (e) =>{
  e.preventDefault()
  if (admin.password === "IDE@#123" && admin.email === "ide@gecg28.ac.in") {
    setLogin(1);
  }
  else{
    alert("Wrong Password");
  }
 }

if (login === 0) {
  return (
    <>
    <ScrollToTop />
    <div className='h-[500px] p-40'>
      <form action="">
    <input
            type="email"
            name="email"
            className="border-2 bg-[#DBE2E2]  w-[80%] h-10 rounded-6 p-5 placeholder:font-semibold my-3 shadow-sm shadow-gray-500 font-semibold text-gray-600 rounded-full"
            placeholder="Email"
            value={admin.email}
            onChange={getloginData}
            autoComplete="off"
            required={true}
          />
          <input
            type="password"
            name="password"
            className="border-2 bg-[#DBE2E2]  w-[80%] h-10 rounded-6 p-5 placeholder:font-semibold my-3 shadow-sm shadow-gray-500 font-semibold text-gray-600 rounded-full"
            placeholder="password"
            value={admin.password}
            onChange={getloginData}
            required={true}
          />
            <button
            type="submit"
            className="uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 button-site"
            onClick={checkpassword}>Submit</button>
            </form>
    </div>
    </>
  )
}
else{


  let name, value;

  const getUserData = (event) => {
    name = event.target.name;
    value = event.target.value;
    setUser({ ...user, [name]: value })
  }

  const postData = async (e) => {

    e.preventDefault();


    const { name, host, mode, date, club, thumbnail, isCompleted, Reportlink } = user;
    if ((name && host && mode && date && club && thumbnail  && Reportlink != null)) {
      const res = await fetch("https://club-ide-events-default-rtdb.firebaseio.com/EventData.json",
        {
          method: "POST",
          headers: {
            "Content-Type": "applicaton/json",
          },
          body: JSON.stringify({
            name: name,
            host: host,
            mode: mode,
            date: date,
            club: club,
            thumbnail: thumbnail,
            isCompleted: Boolean(isCompleted),
            Reportlink: Reportlink
          }),
        }
      );

      if (res) {
        setUser({
          name: "",
          host: "",
          mode: "",
          date: "",
          club: "",
          thumbnail: "",
          isCompleted: "",
          Reportlink: ""

        });
        alert("Event Data submited");
      }
    }
    else {
      // if (fileUpload == null) alert('Please upload a file 😁');
      // else{
      alert('Please Fill the Data 😁');
      // }
    }
  }



  return (
    <>
    <ScrollToTop />
      <div className='h-max py-[150px] w-[80%] m-auto'>

        <h1 className='text-3xl font-bold title-font text-gray-900 mb-4 md:text-5xl'>New Event Data ( Only for Admin )</h1>

        <form action="">
          <input
            type="text"
            name="name"
            className="border-2 bg-[#DBE2E2]  w-[80%] h-10 rounded-6 p-5 placeholder:font-semibold my-3 shadow-sm shadow-gray-500 font-semibold text-gray-600 rounded-full"
            placeholder="Event Name"
            value={user.name}
            onChange={getUserData}
            autoComplete="off"
            required={true}
          />
          <input
            type="text"
            name="host"
            className="border-2 bg-[#DBE2E2]  w-[80%] h-10 rounded-6 p-5 placeholder:font-semibold my-3 shadow-sm shadow-gray-500 font-semibold text-gray-600 rounded-full"
            placeholder="Event Host"
            value={user.host}
            onChange={getUserData}
          />

          <select name="mode" value={user.mode} onChange={getUserData} className="border-2  w-[80%] h-10 px-4 bg-[#DBE2E2] rounded-6 my-3 shadow-sm shadow-gray-500 font-semibold text-gray-600 rounded-full" required={true}>
            <option value="">Event Mode</option>
            <option value="online">Online</option>
            <option value="offline">Offline</option>
          </select>
          <input
            type="date"
            name="date"
            className="border-2 bg-[#DBE2E2]  w-[80%] h-10 rounded-6 p-5 placeholder:font-semibold my-3 shadow-sm shadow-gray-500 font-semibold text-gray-600 rounded-full"
            value={user.date}
            onChange={getUserData}
          />
          <input
            type="text"
            name="club"
            className="border-2 bg-[#DBE2E2]  w-[80%] h-10 rounded-6 p-5 placeholder:font-semibold my-3 shadow-sm shadow-gray-500 font-semibold text-gray-600 rounded-full"
            placeholder='Club Name'
            value={user.club}
            onChange={getUserData}
          />
          <input
            type="url"
            name="thumbnail"
            className="border-2 bg-[#DBE2E2]  w-[80%] h-10 rounded-6 p-5 placeholder:font-semibold my-3 shadow-sm shadow-gray-500 font-semibold text-gray-600 rounded-full"
            placeholder='Thumbnail Image Link'
            value={user.thumbnail}
            onChange={getUserData}
          />

          <select name="isCompleted" value={user.isCompleted} onChange={getUserData} className="border-2  w-[80%] h-10 px-4 bg-[#DBE2E2] rounded-6 my-3 shadow-sm shadow-gray-500 font-semibold text-gray-600 rounded-full" required={true}>
            <option value=""> Is event Complated?</option>
            <option value="1">yes</option>
            <option value="">No</option>
          </select>
          <input
            type="url"
            name="Reportlink"
            className="border-2 bg-[#DBE2E2]  w-[80%] h-10 rounded-6 p-5 placeholder:font-semibold my-3 shadow-sm shadow-gray-500 font-semibold text-gray-600 rounded-full"
            placeholder='Reportlink'
            value={user.Reportlink}
            onChange={getUserData}
          />
          <button
            type="submit"
            className="uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 button-site"
            onClick={postData}

          >Submit</button>
        </form>
      </div>
    </>
  )
}
}
