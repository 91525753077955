import React from 'react'

export default function Attendace_Application() {
  return (

         <section className="text-gray-600 body-font px-0 lg:px-20 py-20 mx-0 lg:mx-10">
                <div className="container mx-auto flex px-2 py-24  flex-col lg:flex-row items-center">
                    <div className="lg:flex-grow lg:text-left lg:w-1/2 lg:pr-16 flex flex-col items-center mb-16 md:mb-0 lg:items-start text-center">
                        <h1 className="title-font text-xl md:text-xl mb-4 font-bold lg:text-xl text-gray-900">Download the application below to fulfill your attendance in Bootcamp.
                        </h1>
                        <div className="flex justify-center">
                            <a href='https://drive.google.com/file/d/1B9MRnNnfFrm3NXiV-Tc9yyuS3X3VHntm/view?usp=sharing' className="w-full h-10 px-10 py-2 text-small text-white items-center  transition-colors duration-200 transform bg-primary  lg:w-auto hover:bg-secondary font-bold md:mb-20 rounded button-site">Downlod Application</a>

                        </div>
                    </div>
                </div>
            </section>

    )
}
