import React from 'react'
import img from '../../imgs/Blog_Imgs/Author_Imgs/demo.png'

import Ecell1 from '../../imgs/Blog_Imgs/Post_Imgs/Ecell-1.jpg'
import Ecell1_1 from '../../imgs/Blog_Imgs/Post_Imgs/Ecell_1_1.png'
import Ecell1_2 from '../../imgs/Blog_Imgs/Post_Imgs/Ecell_1_2.png'
import ScrollToTop from '../ScrollToTop'
import Share_Blog from './Share_Blog';

export default function Ecell_2nd_Nov() {
    return (
        <>
        <ScrollToTop />
            <div className='container pt-28 w-max m-auto '>
                <div className="py-5 ">
                    <h1 className="mb-20 pb-5 border-b-4 border-yellow text-6xl font-bold w-max m-auto text-primary">Blog Details</h1>
                </div>
                <img src={Ecell1} className="scale-[0.8] w-[98.5vw] h-auto lg:scale-[1]" alt="..." />
            </div>
            <div>
                <div className='countiner bg-white border-2 border-gray-100 shadow-lg w-[90%] lg:w-[60%] m-auto h-[max-content] my-10 px-8 pt-10 lg:px-14 relative top-[0px] lg:top-[-400px] pb-10'>
                    <h1 className='h1 text-2xl font-bold lg:text-5xl'>SMART STRATEGIES TO GROW YOUR BUSINESS</h1>
                    <div className='pt-5'>
                        <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">#bussiness</span>
                        <span className="bg-pink-100 text-pink-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-pink-200 dark:text-pink-900">#startup</span>
                        <span className="bg-purple-100 text-purple-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-purple-200 dark:text-purple-900">#entrepreneurship</span>

                    </div>
                    <div className='flex mt-5'>
                        <img class="w-10 h-10 rounded-full " src={img} alt="Rounded avatar" />
                        <span>
                            <h4 className='mx-5 my-0'>Priyangi Sisodiya</h4>
                            <h2 className='text-gray-400 text-sm ml-4'>Nov 2nd 2022 Originally Publlish at <a href='/'><span className='text-primary'> ClubIDE.com </span></a>| 12 min read</h2>
                        </span>
                    </div>
                    <div className='pt-20'>
                        <div className='text-base'>
                            Every successful business today, was once a startup. They didn’t develop overnight. Every
                            business has its own story of achievement. "Do you people want to know the strategies
                            they used to expand their business and build their empire of success?" From startups to
                            large organizations, growing a business in today’s competitive scenario is quite a tough job,
                            right?
                        </div>
                    </div>
                    <div className='pt-5'>
                        <div className="imagebox flex flex-wrap md:flex-nowrap lg:flex-nowrap">
                            <div className='text-img'>
                                But instead of worrying about the
                                competitions, if we look at the positive side
                                of getting to learn new things and methods
                                from a larger number of people, that may
                                prove to be beneficial to us.<br /><br />
                                So, if you’re one of those wanting to grow
                                your business, then you’re in the right place.
                                This post covers such ideas and strategies.
                            </div>
                            <div>
                                <img src={Ecell1_1} className="center" width="85%" height="85%"></img>
                            </div>
                        </div>

                        <div className='pt-5 py-2'>
                            <h1 className='text-2xl font-bold'>1) Choose your team carefully:</h1>
                        </div>
                        <div className='pt-5'>
                            <div className='text-base'>
                                You alone are capable of building your business, but with a strong foundational team that
                                believes in you and your vision, you can reach new heights. Look for partners who add to
                                your skills and get help whenever necessary.
                            </div>
                        </div>

                        <div className='pt-5 py-2'>
                            <h1 className='text-2xl font-bold'>2) Do your research:</h1>
                        </div>
                        <div className='pt-5'>
                            <div className='text-base'>
                                Every time, we find shortcuts for the problems and avoid wasting time on research.
                                Researching may seem to take some precious time, but it will assist you in setting your plan,
                                which will ultimately save a lot of time.
                            </div>
                        </div>

                        <div className='pt-5 py-2'>
                            <h1 className='text-2xl font-bold'>3) Solve a problem:</h1>
                        </div>
                        <div className='pt-5'>
                            <div className='text-base'>
                                Build a product that will genuinely solve a problem. Convince the people about the solution
                                you have come up with.
                            </div>
                        </div>

                        <div className='pt-5 py-2'>
                            <h1 className='text-2xl font-bold'>4) Set short- and long-term goals:</h1>
                        </div>
                        <div className='pt-5'>
                            <div className='text-base'>
                                Setting short-term goals is one of the most important tricks in business development. Instead
                                of one long race, get involved in numerous short races. After achieving each short-term goal,
                                analyse your performance.
                            </div>
                        </div>

                        <div className="imagebox flex flex-wrap md:flex-nowrap lg:flex-nowrap">
                            <div className='text-img'>
                                Try to keep them smart. SMART means –
                                <ul className='uppercase text-green-600 font-bold'>
                                    <li>SPECIFIC</li>
                                    <li>MeaSurable</li>
                                    <li>achievable</li>
                                    <li>relevant</li>
                                    <li>tiMe-bound</li>
                                </ul>
                                <br />
                            </div>
                            <div>
                                <img src={Ecell1_2} className="text-center" width="100%" height="100%"></img>
                            </div>

                        </div>


                        <div className='pt-5 py-2'>
                            <h1 className='text-2xl font-bold'>5) Never compromise on quality:</h1>
                        </div>
                        <div className='pt-5'>
                            <div className='text-base'>
                                Do not forget about maintaining the quality of your
                                products or services. Remember, customer service and product quality are both equally
                                important for any business. Degradation in quality leads customers to find your alternative.
                            </div>
                        </div>

                        <div className='pt-5 py-2'>
                            <h1 className='text-2xl font-bold'>6) Follow current trends:</h1>
                        </div>
                        <div className='pt-5'>
                            <div className='text-base'>
                                Following the same trends or the same advice from traditional leaders will never make us
                                move ahead. Stay alert about market trends. Refine the plan if necessary. Keep in mind that
                                timing plays a vital role in marketing. You can stand out in the competition easily when you
                                are aware of the current trends.
                            </div>
                        </div>

                        <div className='pt-5 py-2'>
                            <h1 className='text-2xl font-bold'>7) Plan for failure:</h1>
                        </div>
                        <div className='pt-5'>
                            <div className='text-base'>
                                Many people do not have the courage to start something new on their own because of the fear
                                of failure. But failure upon starting something new is inevitable, and it is important that one
                                be prepared for that in order to adapt to the situation and learn quickly.
                                Planning for failure does not mean you are planning to fail. The <b> end goal is always success.</b>
                                As it is said, the road to success is riddled with failed concepts and ideas.
                            </div>
                        </div>

                        <div className='pt-5 py-2'>
                            <h1 className='text-2xl font-bold'>Conclusion:</h1>
                        </div>
                        <div className='pt-5'>
                            <div className='text-base'>
                                After knowing all the strategies, a common thing you could have noticed is, hard work and
                                consistency. If you are willing to work with integrity and believe in yourself, you are not far
                                from writing your own story of creating a powerful empire.
                            </div>
                        </div>


                    </div>

                </div>
                        <Share_Blog />
            </div>

        </>
    )

}
