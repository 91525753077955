import ScrollToTop from "../../Components/ScrollToTop";
import { useState } from "react";
import Eventcard from "./Eventcard";

import EventsImg from '../../imgs/event.png';
import { useEffect } from "react";

import { getDatabase, ref, child, get } from "firebase/database";
import { events } from "./EventData";


const Eventpage = () => {

    const [Events, setEvents] = useState({});

    useEffect(() => {
        const dbRef = ref(getDatabase());
        get(child(dbRef, `EventData/`)).then((snapshot) => {
            if (snapshot.exists()) {

                setEvents(snapshot.val());
            } else {
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        })
    }, [])

    const NewEvents = [];
    for (const key in Events) {
        const mappedData = {
            ...Events[key],
        };
        NewEvents.push(mappedData);
    }



    let Allevents = events.concat(NewEvents);
    Allevents = Allevents.reverse();


    let [eventsFiltered, setEventsFiltered] = useState([]);


    const showPastEvents = () => {
        eventsFiltered = Allevents.filter(event => event.isCompleted === true);
        setEventsFiltered(eventsFiltered);
    }
    const showUpcomingEvents = () => {
        eventsFiltered = Allevents.filter(event => event.isCompleted === false);
        setEventsFiltered(eventsFiltered.reverse());

    }

    return (
        <>
            <ScrollToTop />


            {/* Event Card Section is Start From Here  */}

            <div className="pt-32 ">
                <h1 className=" pb-4  text-6xl font-bold w-max m-auto text-primary">Events</h1>
            </div>
            <div className="flex justify-evenly items-center w-[100%] m-auto">
                <button className="mt-20 bg-white shadow-lg shadow-gray-600  px-6 py-3 border-l-4 border-secondary hover:drop-shadow-xl blog-buttons font-medium text-sm lg:text-xl" onClick={showUpcomingEvents}>Upcoming Events</button>
                <button className="mt-20 bg-white shadow-lg shadow-gray-600  px-6 py-3  border-l-4 border-secondary hover:drop-shadow-xl blog-buttons font-medium text-sm lg:text-xl" onClick={showPastEvents}>Past Events</button>
            </div>


            <div className="flex flex-wrap justify-evenly lg:gap-8 gap-0 items-center mt-20 lg:px-10 md:px-12">

                {eventsFiltered.length === 0 ? (
                     Allevents.map((event) =>{
                        return <Eventcard events = {event} />
                    })
                ):(
                    eventsFiltered.map((event) => {
                        return <Eventcard events={event} />
                    })
                )}
            </div>


            {/* Event Card Section is End  */}



            {/* From Here WorkShop Section is statred  */}

            <div className="pt-32 ">
                <h1 className=" pb-4  text-6xl font-bold w-max m-auto text-primary">Workshops</h1>
            </div>
            <div className="flex justify-evenly items-center w-[100%] m-auto">
                <button className="mt-20 bg-white shadow-lg shadow-gray-600 px-6 py-3 border-l-4 border-secondary hover:drop-shadow-xl blog-buttons font-medium text-sm lg:text-xl">Upcoming Workshop</button>

                <button className="mt-20 bg-white shadow-lg shadow-gray-600  px-6 py-3  border-l-4 border-secondary hover:drop-shadow-xl blog-buttons font-medium text-sm lg:text-xl">Past Workshop</button>
            </div>

            <div className="items-center lg:flex mt-16">

                <div className="flex items-center justify-center w-full mt-6 lg:mt-0 lg:w-1/2">
                    <img className="w-[50%] h-[50%] lg:max-w-2xl" src={EventsImg} alt="Catalogue-pana.svg" />
                </div>


                <div className="w-full lg:w-1/2">
                    <div className="mx-0 lg:max-w-lg lg:mx-20">
                        <p className="mt-6 lg:text-3xl text-center text-xl text-gray-500 leading-9">We're Wroking on Workshops ideas<br />Stay Tuned </p>
                    </div>
                </div>
            </div>



        </>
    );
}

export default Eventpage;