import React from 'react'
import img from '../../imgs/Blog_Imgs/Author_Imgs/demo.png'

import Ecell7 from '../../imgs/Blog_Imgs/Post_Imgs/Ecell-7.jpg'
import ScrollToTop from '../ScrollToTop';
import Share_Blog from './Share_Blog';

export default function Ecell_14th_Dec() {
    return (
        <>
        <ScrollToTop />
            <div className='container pt-28 w-max m-auto '>
                <div className="py-5 ">
                    <h1 className="mb-20 pb-5 border-b-4 border-yellow text-6xl font-bold w-max m-auto text-primary">Blog Details</h1>
                </div>
                <img src={Ecell7} className="scale-[0.8] w-[98.5vw] h-auto lg:scale-[1]" alt="..." />
            </div>
            <div>
                <div className='countiner bg-white border-2 border-gray-100 shadow-lg w-[90%] lg:w-[60%] m-auto h-[max-content] my-10 px-8 pt-10 lg:px-14 relative top-[0px] lg:top-[-400px] pb-10'>
                    <h1 className='h1 text-2xl font-bold lg:text-5xl'>START UP </h1>
                    <div className='pt-5'>
                        <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">#Bussiness</span>
                        <span className="bg-pink-100 text-pink-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-pink-200 dark:text-pink-900">#start up</span>

                    </div>
                    <div className='flex mt-5'>
                        <img class="w-10 h-10 rounded-full " src={img} alt="Rounded avatar" />
                        <span>
                            <h4 className='mx-5 my-0'>AVINASH TANTI</h4>
                            <h2 className='text-gray-400 text-sm ml-4'>14th Dec 2022 Originally Publlish at <a href='/'><span className='text-primary'> ClubIDE.com </span></a>| 12 min read</h2>
                        </span>
                    </div>
                    <div className='text-base mt-2'>
                        The word "startup" has so many meanings, but for me, "startup" is actually motivating oneself to just START doing
                        things in a constructive manner and get up to brainstorm new ideas .There’s a famous saying that "necessity is the
                        mother of invention." Start-ups are generally initiated by creative minds who once faced issues in their domain or
                        society and then tried to solve those issues .<br /><br />
                        It takes a series of small steps to turn a startup into a unicorn. The foremost step is to find the root cause of the
                        problem and identify if it is really a problem that can be solved by a startup. There are many aspects of a good
                        startup that need to be taken care of. It requires a lot of brainstorming when the start-up is at the idea stage. You
                        must have all the bases covered and have all the answers for your product or venture. The solution that you found
                        must have a logical foundation and some proof of its execution .<br /><br />
                        A great startup demands a great team. But choosing the right team that completely and perfectly fits your idea of
                        setup is the most important task. Once you have the team as per your needs, you’re ahead of 75% of other
                        entrepreneurs. Giving the right role to the right person is also very important. The person behind all these
                        decisions should be the one with the idea, as the team members will only listen to what the initiator has to say, but
                        that one person has all things already present in their mind <br /><br />
                        To express the idea in the most professional way possible is a key factor that determines the future of a venture.
                        knowledge of the target available market, target market, serviceable market, and service-obtainable market. The
                        strategies should be made after doing a lot of research on the domain of the product . You should be clear with the
                        growth plans of your venture to sustain it with all the current as well as future expectations. The venture must be
                        open to expanding and adapting. A clear insight into the target customers is very important. A startup cannot go
                        on a long run with money involved. Hence, it becomes a necessity that you have a proper and logical revenue
                        model for the venture you are working on. Digging deep into all sources available, you must have the answer to
                        keep your customers and target market engaged with you. Providing proper and effective service is a must while
                        knowing about the retention market too One who wants to build a startup from scratch must know that 99% of startups fail just because the
                        founders lost their motivation and couldn’t hold onto the situation when things went wrong. You must
                        have confidence and faith in your own idea, even if the world doesn’t believe in it; they will once it
                        becomes a success, and for that, you have to work rigorously towards your goal and keep hustling. Just
                        remember to never give up. Some might find early success while others have to just deal with failures,
                        but people belonging to that 1 % all had one thing in common THAT they never let outside noise change
                        their plans. Hear what others have to say, but take your own decision . Be the solution architect and keep
                        surveying about your product.
                    </div>

                </div>

                <Share_Blog />
            </div>
        </>
    )
}
