import Modal from '../Components/Modal.js'
import ScrollToTop from "../Components/ScrollToTop";
import { useState } from 'react';

import BlogCard from "../Components/Blogs/Blog_Page_Components/BlogCard";
import Blog_1042022 from "../imgs/Blog_Imgs/Post_Imgs/apr22_1.jpg";

import Blog_2042022 from "../imgs/Blog_Imgs/Post_Imgs/apr22_2.jpg";

import Blog_1032022 from "../imgs/Blog_Imgs/Post_Imgs/mar22_1.jpg";

import Blog_2032022 from '../imgs/Blog_Imgs/Post_Imgs/mar22_2.jpg';

import Blog_1022022 from '../imgs/Blog_Imgs/Post_Imgs/feb22_1.jpg';

import Blog_2022022 from '../imgs/Blog_Imgs/Post_Imgs/feb22_2.png';

import Blog_1012022 from '../imgs/Blog_Imgs/Post_Imgs/jan22_1.jpg';

import Blog_2012022 from '../imgs/Blog_Imgs/Post_Imgs/jan22_2.jpg';

import Blog_1122021 from '../imgs/Blog_Imgs/Post_Imgs/dec21_1.jpg';

import Blog_2122021 from '../imgs/Blog_Imgs/Post_Imgs/dec21_2.jpg';

import Blog_1112021 from '../imgs/Blog_Imgs/Post_Imgs/nov21_1.jpg';

import Blog_2112021 from '../imgs/Blog_Imgs/Post_Imgs/nov21_2.jpg';

import Blog_1102021 from '../imgs/Blog_Imgs/Post_Imgs/oct21_1.jpg';

import Blog_2102021 from '../imgs/Blog_Imgs/Post_Imgs/oct21_2.webp';

import Blog_1092021 from '../imgs/Blog_Imgs/Post_Imgs/sept21_1.jpg';

import Blog_2092021 from '../imgs/Blog_Imgs/Post_Imgs/sept21_2.png';
import Blog_1072022 from '../imgs/Blog_Imgs/Post_Imgs/july22_1.png'
import Blog_2072022 from '../imgs/Blog_Imgs/Post_Imgs/july22_2.jpg'
import Blog_1082022 from '../imgs/Blog_Imgs/Post_Imgs/aug22_1.png'
import Blog_2082022 from '../imgs/Blog_Imgs/Post_Imgs/aug22_2.jpg'
import Blog_1092022 from '../imgs/Blog_Imgs/Post_Imgs/sept22_1.png'
import Ecell1 from '../imgs/Blog_Imgs/Post_Imgs/Ecell-1.jpg';
import Ecell2 from '../imgs/Blog_Imgs/Post_Imgs/Ecell-2.jpg';
import Ecell3 from '../imgs/Blog_Imgs/Post_Imgs/Ecell-3.jpg';
import Ecell4 from '../imgs/Blog_Imgs/Post_Imgs/Ecell-4.jpg';
import Ecell5 from '../imgs/Blog_Imgs/Post_Imgs/Ecell-5.jpg';
import Ecell6 from '../imgs/Blog_Imgs/Post_Imgs/Ecell-6.jpg';
import Ecell7 from '../imgs/Blog_Imgs/Post_Imgs/Ecell-7.jpg';
import Ecell9 from '../imgs/Blog_Imgs/Post_Imgs/Ecell-9.png';
import Ecell8 from '../imgs/Blog_Imgs/Post_Imgs/Ecell-8.jpg';



const Blogpage = () => {

  let blogCards = [
    {
      id: 'Ecell_27th_Dec',
      backImage: Ecell9,
      date: 'Dec-22',
      title: `How to Start a Startup in India`,
      type: 'Start up',
      desc: `Every startup is a dream, before turning into a reality and to make the reality
      worthwhile, it is important to know about & understand its very perspectives and
      then plan on achieving the bigger goal`,
      category: 'non-technical',
      AppreanceNumber: '29',
    },
    {
      id: 'Ecell_21st_Dec',
      backImage: Ecell8,
      date: 'Dec-22',
      title: `The Power of your START UP`,
      type: 'Start up',
      desc: `The term start-up refers to a company in the initial stages of
      operations. Start-ups are established by one or more entrepreneurs
      who want to develop a product or service for which they believe there
      is demand. The goal of a start-up is to create something new or
      improve something that currently exists. Start-ups are risky and
      require a lot of time and effort, however, once successful, the output
      is astounding. `,
      category: 'non-technical',
      AppreanceNumber: '29',
    },
    {
      id: 'Ecell_14th_Dec',
      backImage: Ecell7,
      date: 'Dec-22',
      title: `START UP `,
      type: 'Start up',
      desc: `he word "startup" has so many meanings, but for me, "startup" is actually motivating oneself to just START doing
      things in a constructive manner and get up to brainstorm new ideas .There’s a famous saying that "necessity is the
      mother of invention." Start-ups are generally initiated by creative minds who once faced issues in their domain or
      society and then tried to solve those issues .`,
      category: 'non-technical',
      AppreanceNumber: '28',
    },
    {
      id: 'Ecell_7th_Dec',
      backImage: Ecell6,
      date: 'Dec-22',
      title: `Why It's Important to Say NO in Corporate World !!`,
      type: 'How to say No',
      desc: `In the present situation, people and students do not have money so that they can fulfill the needs of their families. In this situation, people are also unemployed.
      We can develop any skill in this time so that we can fulfill our needs by business startup. Today many people are fulfilling their needs by start business online.`,
      category: 'non-technical',
      AppreanceNumber: '27',
    },
    {
      id: 'Ecell_30th_Nov',
      backImage: Ecell5,
      date: 'Nov-22',
      title: 'Top 5 Business Startup Online Without Money For Beginners',
      type: 'Business',
      desc: `In the present situation, people and students do not have money so that they can fulfill the needs of their families. In this situation, people are also unemployed.
      We can develop any skill in this time so that we can fulfill our needs by business startup. Today many people are fulfilling their needs by start business online.`,
      category: 'non-technical',
      AppreanceNumber: '26',
    },
    {
      id: 'Ecell_23rd_Nov',
      backImage: Ecell4,
      date: 'Nov-22',
      title: 'JAVA JACKETS',
      type: 'Cooffe',
      desc: `You might be reading this with a cup of coffee in your hand and enjoying the warmth of the
      coffee on your palm and that mesmerizing taste on your taste buds. But what if this cozy warmth
      turns out to be the reason of your finger burn.
      This is what happed with Mr. Jay Sorenson from Portland, Oregon, once while having his coffee
      on the way to his daughter's school, he spilled the coffee and burned his fingers. This burning of
      finger flashed a thought in Jay's mind, that every morning one or another might be going
      through hard holding a hot cup of Coffee/Tea and this made him do something and two years
      later, he came up with JAVA JACKETS, a coffee cup sleeve brand that cuddles your coffee
      making you enjoy the sip without thinking of any finger burns or spills.`,
      category: 'non-technical',
      AppreanceNumber: '25',
    },
    {
      id: 'Ecell_16th_Nov',
      backImage: Ecell3,
      date: 'Nov-22',
      title: 'India New Hub of Start-up',
      type: 'startup',
      desc: `India’s Start-up journey is not a recent one, India has already started their journey in 80s and the 90s company like Infosys and Airtel, along with many other Start-ups.
      In past few years,India played major role in the global start-up ecosystem, along with the US, China, United Kingdom and others.`,
      category: 'non-technical',
      AppreanceNumber: '24',
    },
    {
      id: 'Ecell_9th_Nov',
      backImage: Ecell2,
      date: 'Nov-22',
      title: 'Is innovation required?',
      type: 'Innovation',
      desc: `Innovation is the design, development, and deployment of a new product, process, or
      service with the goal of increasing efficiency, effectiveness, or competitive advantage. When
      people begin to think outside the box, when they cannot see themselves adjusting in the
      boundaries set by the world and mediocre minds, they begin to innovate.`,
      category: 'non-technical',
      AppreanceNumber: '23',
    },
    {
      id: 'Ecell_2nd_Nov',
      backImage: Ecell1,
      date: 'Nov-22',
      title: 'SMART STRATEGIES TO GROW YOUR BUSINESS',
      type: 'Internship',
      desc: `Every successful business today, was once a startup. They didn’t develop overnight. Every
      business has its own story of achievement. "Do you people want to know the strategies
      they used to expand their business and build their empire of success?" From startups to
      large organizations, growing a business in today’s competitive scenario is quite a tough job,
      right?`,
      category: 'non-technical',
      AppreanceNumber: '22',
    },
    {
      id: 1092022,
      backImage: Blog_1092022,
      date: 'Sep-22',
      title: 'BHAGAVAD GITA FOR LIFE...',
      type: 'bhagavadgita ',
      desc: `You have a right to perform your prescribed duties, but you are not entitled to the fruits of your actions. Never consider yourself to be the cause of the results of your activities, nor be attached to inaction.This is probably one of the most famous slokas from the Bhagavad Gita. It offers deep insight into the proper spirit of work and is often quoted whenever the topic of karma yog is discussed. The verse gives four instructions regarding the science of work:`,
      category: 'non-technical',
      AppreanceNumber: '21',
    },
    {
      id: 2082022,
      backImage: Blog_2082022,
      date: 'Aug-22',
      title: 'CARBON : A NEW PROGRAMMING LANGUAGE',
      type: 'beconfident ',
      desc: `There are some days when we feel that there is no hope or no purpose in our life. Even we come up with questions like whatever we are doing is it really even worth it? Do we really make a difference in our life? Are our efforts enough to fulfill our future requirements and needs of ourselves and our family? These questions are obvious when we are in competition with so many better people around us. Also, if these questions come up in your mind, it itself proves that you are actually making an effort.`,
      category: 'technical',
      AppreanceNumber: '20',
    },
    {
      id: 1082022,
      backImage: Blog_1082022,
      date: 'Aug-22',
      title: 'HOW TO BEAT SELF-DOUBT AND NEGATIVITY?',
      type: 'staypositive ',
      desc: `There are some days when we feel that there is no hope or no purpose in our life. Even we come up with questions like whatever we are doing is it really even worth it? Do we really make a difference in our life? Are our efforts enough to fulfill our future requirements and needs of ourselves and our family? These questions are obvious when we are in competition with so many better people around us. Also, if these questions come up in your mind, it itself proves that you are actually making an effort.`,
      category: 'non-technical',
      AppreanceNumber: '19',
    },
    {
      id: 2072022,
      backImage: Blog_2072022,
      date: 'July-22',
      title: 'CODING? NAH JUST PLAYING A GAME',
      type: 'programming',
      desc: ` Everyone of us know that we have help option where we can seek answers to our problems not all but most of them. Just in case if you are too lazy to read through the help menu you can always Google it or ask your friend, it's as simple as asking for help in between a game or ordering food online`,
      category: 'technical',
      AppreanceNumber: '18',
    },
    {
      id: 1072022,
      backImage: Blog_1072022,
      date: 'July-22',
      title: 'HOW TO BE CONSISTENT?',
      type: 'motivation',
      desc: `Developing consistency goes against human nature. It's burdensome, especially at first. It takes a lot of energy, a finite resource. Don't make the process even more challenging by trying to do too much all at once... The trick is to pick one goal to focus on at a time. What's your biggest issue? What's your most damaging inconsistency? Start there. Work at it. Stabilize. Then move on to your next goal.`,
      category: 'non-technical',
      AppreanceNumber: '17',
    },
    {
      id: 1042022,
      backImage: Blog_1042022,
      date: 'April-22',
      title: 'Quality of Work and Time',
      type: 'PersonalDevelopment',
      desc: `Most of us might be facing problems related to time and pending work. Also, we know how much important is time management skill. To do something, it is not important to take much amount of time but important is that quality time and task. In my perspective, you just have to take the right decision at right time. You have to do today's task. Thus, don't need to worry about tomorrow. If you will make the right decision but the time is wrong then your decision will become wrong, too.`,
      category: 'non-technical',
      AppreanceNumber: '16',
    },
    {
      id: 2042022,
      backImage: Blog_2042022,
      date: 'April-22',
      title: 'JUST Follow Up',
      type: 'space',
      desc: `Here is one of the most important space exploration missions of our decade, JWST was in the works for the last 20 years. It is as big as our volleyball ground and colder than the nitro biscuit that you ate last year. It can peek into the past as it can read the faintest of the infrared. This trendy design is more complicated than `,
      category: 'technical',
      AppreanceNumber: '15',
    },
    {
      id: 1032022,
      backImage: Blog_1032022,
      date: 'March-22',
      title: 'Value Addition',
      type: 'inspiration',
      desc: `Sometime ago, I shared a quote on Twitter that says, People will not consider you or listen to you until you add value to their life whether it could be your social media audience, your teammate, etc.  From my perspective, this quote has a deep meaning so let us try to understand it in the next few paragraphs.`,
      category: 'non-technical',
      AppreanceNumber: '14',
    },
    {
      id: 2032022,
      backImage: Blog_2032022,
      date: 'March-22',
      title: 'Bol Behen Chatbot',
      type: 'ArtificialIntelligence',
      desc: `TThe chatbot runs on AI and will help educate women about general and sexual well-being. According to News18, the chatbot will be available in both Hindi and English. "Bol Behen" will cover an array of topics, ranging from relationships, and sexuality, to reproductive health.`,
      category: 'technical',
      AppreanceNumber: '13',
    },

    {
      id: 1022022,
      backImage: Blog_1022022,
      date: 'feb-22',
      title: 'Routine',
      type: 'Motivational',
      desc: `  Routine is a practice that can where you schedule your whole day and spend your time according to it whether it be doing something productive or just watching some web series in between. Routine is very important to keep account of your time. For going on a short date or a short trip if we plan very wisely then`,
      category: 'non-technical',
      AppreanceNumber: '12',
    },
    {
      id: 2022022,
      backImage: Blog_2022022,
      date: 'feb-22',
      title: '5G Technology',
      type: 'technology',
      desc: ` 5G is the fifth-generation wireless connectivity that would make digitalization more accessible for businesses, public services, and individual households to reap the benefits of IoT, industry 4.0, and the explosion of intelligent devices. The next generation of wireless connectivity, or 5G, acts as a catalyst that promotes the advancement of intelligent connectivity. `,
      category: 'technical',
      AppreanceNumber: '11',
    },
    {
      id: 1012022,
      backImage: Blog_1012022,
      date: 'jan-22',
      title: 'Still Learning Always Learning',
      type: 'knowledge',
      desc: `  Knowledge means the theoretical or practical understanding of a subject. There is a big difference between knowledge and knowing. To know is like you know the sun rises in the east but to have the knowledge means to know that the sun rises in the east because the earth rotates from west to east. `,
      category: 'non-technical',
      AppreanceNumber: '10',
    },
    {
      id: 2012022,
      backImage: Blog_2012022,
      date: 'jan-22',
      title: 'The Meta-Verse',
      type: 'metaverse',
      desc: ` Metaverse is a virtual-reality space in which users can interact with a computer-generated environment and other users. A metaverse is a network of 3D virtual worlds focused on social connection. 3D spaces in the metaverse will let you socialize, learn, collaborate and play in ways that go beyond `,
      category: 'technical',
      AppreanceNumber: '9',
    },
    {
      id: 1122021,
      backImage: Blog_1122021,
      date: 'Dec-21',
      title: 'A Common Man',
      type: 'lifestyle',
      desc: `  Is life sumptuous for a common man? NEVER. A common man can never gain whatever he needs. I’m talking about the Indian system. As most of my viewers belong to US and UK they might not be aware of this. Well, in India, a hard-working man has no worth while the smart working men are halfway up and halfway down. `,
      category: 'non-technical',
      AppreanceNumber: '8',
    },
    {
      id: 2122021,
      backImage: Blog_2122021,
      date: 'Dec-21',
      title: 'Feature of Future',
      type: 'technology',
      desc: `In WhatsApp, users could send a voice message to another user, but they did not get a chance to listen to that voice message before sending it. However, this often led to the user sending the wrong voice message. With this in mind, WhatsApp has changed its feature and given a new feature to give `,
      category: 'technical',
      AppreanceNumber: '7',
    },
    {
      id: 1112021,
      backImage: Blog_1112021,
      date: 'Nov-21',
      title: 'Stay Strong',
      type: 'Motivaional',
      desc: ` We all have had a phase where we feel lonely and left out! This is one of the toughest parts of life, but eventually, we all get through it. We grow stronger. We grow out bigger than the problems we are facing. Some do this in days while some can take a few years. But this is required. `,
      category: 'non-technical',
      AppreanceNumber: '6',
    },
    {
      id: 2112021,
      backImage: Blog_2112021,
      date: 'Nov-21',
      title: 'History of Web',
      type: 'internet',
      desc: `We are constantly connected to devices. These devices communicate with each other to make our lives easier. We've become so immersed in technology that we don't even notice that a simple smartwatch app is replacing heavier equipment to keep our health vitals in check. Web 3.0 is the next step in the web's growth `,
      category: 'technical',
      AppreanceNumber: '5',
    },
    {
      id: 1102021,
      backImage: Blog_1102021,
      date: 'Oct-21',
      title: 'Wake Up Note',
      type: 'Motivational',
      desc: `We wake up to this evil world every morning and so we may appreciate ourselves and other people too. It takes lots of guts to survive in this world, I must say. Things are not as simple as they seem to be.To all those people who are living healthily, you all are amazing. If no one told you today how beautiful you are or `,
      category: 'non-technical',
      AppreanceNumber: '4',
    },
    {
      id: 2102021,
      backImage: Blog_2102021,
      date: 'Oct-21',
      title: 'Blockchain',
      type: 'Techonology',
      desc: `Blockchain is the core technology used to create the cryptocurrencies, like bitcoin. As part of the 4th industrial revolution since the invention of steam engine, electricity, and information technology, blockchain technology has been applied in many areas such as finance, judiciary, and commerce. The current paper focused on its potential`,
      category: 'technical',
      AppreanceNumber: '3',
    },
    {
      id: 1092021,
      backImage: Blog_1092021,
      date: 'Sept-21',
      title: 'Motivation comes from within',
      type: 'Motivational',
      desc: ` Many times people think that motivation comes from reading inspirational stories, watching real-life inspired movies, hearing motivational speakers, etc. This may help you for a short period of time; like you have heard or watched something motivational, you feel inspired and when you are exhausted, you stop. But what I believe is that motivation comes from within.`,
      category: 'non-technical',
      AppreanceNumber: '2',
    },
    {
      id: 2092021,
      backImage: Blog_2092021,
      date: 'Sept-21',
      title: 'Internet Of Things',
      type: 'InternetOfThings',
      desc: `As the word itself describes, the Internet of Things (IoT), is a technology that connects real-world objects or things to the Internet. These objects are controlled using a smartphone through the Internet. Various sensors are used for the detection of different factors.
          Sensors can sense the human presence, atmospheric pressure, soil state, human body status etc. Sensors are connected with a microcontroller centralized device that controls all the data sent sensors. Two famous microcontroller devices are Arduino and Raspberry Pi.`,
      category: 'technical',
      AppreanceNumber: '1',
    },


  ];
  let [blogCardsFiltered, setBlogCardsFiltered] = useState(blogCards);


  const showTechnical = () => {
    blogCardsFiltered = blogCards.filter(blogcard => blogcard.category === 'technical');
    setBlogCardsFiltered(blogCardsFiltered);
    ;
  }
  const showNonTechnical = () => {
    blogCardsFiltered = blogCards.filter(blogcard => blogcard.category === 'non-technical');
    setBlogCardsFiltered(blogCardsFiltered);
  }


  // const nums = [1,2,3,4,5,6,7];
  // console.log(nums.filter(num=>num>3));
  return (
    <>

      <ScrollToTop />

      <div className="pt-32">
        <h1 className=" pb-4 border-b-4 border-yellow text-6xl font-bold w-max m-auto text-primary">Blogs</h1>
      </div>
      <div className="flex justify-evenly items-center mb-24 lg:mb-0 ">
        <button className="mt-16 bg-white shadow-lg shadow-gray-600 px-6 py-3 text-xl border-l-4 border-secondary hover:drop-shadow-xl blog-buttons font-medium" onClick={showTechnical}>Technical</button>
        <button className="mt-16 bg-white shadow-lg shadow-gray-600  px-6 py-3 text-xl border-l-4 border-secondary hover:drop-shadow-xl blog-buttons font-medium" onClick={showNonTechnical}>Non Technical</button>
      </div>

      <div className="flex flex-wrap justify-center lg:gap-5 gap-0 items-center lg:px-10 md:px-12 mt-12  ">

        <BlogCard data_blogcard={blogCardsFiltered} />
        {/* <Routes>
                <Route path='sept21_blog1' element={<Sept21_Blog1 />}></Route>
              </Routes> */}
      </div>
      <div className='w-max m-auto my-10 mt-20'>
        <Modal text='Submit Your Blog' />
      </div>


    </>
  );
}

export default Blogpage;