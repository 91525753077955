import TeamMembers from './TeamMembers';
import TeamImage from '../../imgs/Team.png'
import ScrollToTop from '../../Components/ScrollToTop';
import Param_Radadiya from '../../imgs/leads/Param_Radadiya.jpg';
import Ashwin_Prajapati from '../../imgs/Faculties/Ashwin_Prajapati.jpg';
import Dhaval_Parikh from '../../imgs/Faculties/Dhaval_Parikh.jpg';
import Yogendra_Tank from '../../imgs/Faculties/Yogendra_Tank.jpg';
import ArrowDown from '../../imgs/arrow_drop_down.svg'
import { Link } from 'react-router-dom';
import {FaLinkedinIn , FaGithub} from 'react-icons/fa';
import {MdOutlineEmail} from 'react-icons/md';
import Flippy, {FrontSide, BackSide} from 'react-flippy';
import '../../index.css'
import 'react-lazy-load-image-component/src/effects/blur.css';

const Team = () => {
    const openDropDown = () => {

        var open = document.getElementById('dropdown');
        var displaySetting = open.style.display;
        if (displaySetting == 'block') {
            open.style.display = 'none';
            document.getElementById('Arrowdown').style.transform  = "rotate(-90deg)"
        }
        else {
            open.style.display = 'block';
            document.getElementById('Arrowdown').style.transform  = "rotate(0deg)"
        }
    }


    const faculties = [{ name: 'Dr.Dhaval Parikh', role: 'HOD/Senior Advisor', email: 'mailto:daparikh@gecg28.ac.in', photo: Dhaval_Parikh },
    { name: 'Prof. Yogendra Tank', role: 'Faculty Coordinator', email: 'mailto:yogendratank@gecg28.ac.in', photo: Yogendra_Tank },
    { name: 'Prof. Ashwin Prajapati', role: 'Faculty Coordinator', email: 'mailto:ashvinkumar@gecg28.ac.in', photo: Ashwin_Prajapati },

    ]
    const Founder = [
        { name: 'Param Radadiya', role: 'Founder', email: 'mailto:paramradadiya3@gmail.com', desc:'Hey, I\'m Param Radadiya!! The founder and ex-president of the Club IDE. Currently, I\'m working on AR VR Development and as a hobby, I\'m a DIY person. I loves to interact with people. I\'m a leader, who is creating more leaders : \)', photo: Param_Radadiya , linkDin: "Param Radadiya" },
    ]


    return (
        <>
            <ScrollToTop />
            <section className="text-gray-600 body-font">
                <div className="container mx-auto lg:flex flex-col  py-6 md:flex-row items-center bg-[#e5e7eb] max-w-full">
                    <div className="lg:flex-grow md:w-1/2 lg:pr-24 lg:text-left flex flex-col md:items-start  lg:mb-16 md:mb-0 items-center text-center lg:ml-32 md:mx-auto">
                        <h1 className="title-font font-bold xl:text-[3.5rem] text-[2rem] mt-32 leading-normal mb-4 text-secondary">Meet Our
                            Super Enthusiastic Team
                        </h1>

                    </div>
                    <div className="lg:max-w-2xl lg:w-full md:w-1/2 w-5/6 mx-auto ">
                        <img className="object-cover object-center " alt="hero" src={TeamImage} />
                    </div>
                </div>
            </section>
            <div className='w-max ml-auto mt-2 mr-2 mb-20 ' onClick={openDropDown}>
                        <div className='w-max py-1 pl-4  text-base font-semibold text-center border-2 bg-slate-200 rounded cursor-pointer'>Select year
                         <img src={ArrowDown} className="w-8 relative bottom-[1px] inline-block Arrowdown" id='Arrowdown' />
                        </div>
                            <ul className=" py-1 text-sm text-gray-900 text-center  bg-white shadow-lg w-34 dropdown overflow-auto" id='dropdown'>
                                <li className='hover:bg-slate-200 text-lg'>
                                    <Link to='/2022'>2022</Link>
                                </li>
                                <li className='hover:bg-slate-200 text-lg'>
                                    <Link to='/2021'>2021</Link>
                                </li>
                                </ul>
                                </div>

            <TeamMembers title={'Faculties'} members={faculties} />
            <TeamMembers title={'Founder'} members={Founder} classNameForCenter = {'w-max m-auto'} />

            <div>

            </div>
        </>


    );

}

export default Team;