import React from 'react'
import img from '../../imgs/Blog_Imgs/Author_Imgs/demo.png'

import Ecell3 from '../../imgs/Blog_Imgs/Post_Imgs/Ecell-3.jpg'
import Ecell2_1 from '../../imgs/Blog_Imgs/Post_Imgs/Ecell_2_1.png'
import Ecell2_2 from '../../imgs/Blog_Imgs/Post_Imgs/Ecell_2_2.png'
import Ecell2_3 from '../../imgs/Blog_Imgs/Post_Imgs/Ecell_2_3.png'
import ScrollToTop from '../ScrollToTop'
import Share_Blog from './Share_Blog';

export default function Ecell_16th_Nov() {
    return (
        <>
        <ScrollToTop />
            <div className='container pt-28 w-max m-auto '>
                <div className="py-5 ">
                    <h1 className="mb-20 pb-5 border-b-4 border-yellow text-6xl font-bold w-max m-auto text-primary">Blog Details</h1>
                </div>
                <img src={Ecell3} className="scale-[0.8] w-[98.5vw] h-auto lg:scale-[1]" alt="..." />
            </div>
            <div>
                <div className='countiner bg-white border-2 border-gray-100 shadow-lg w-[90%] lg:w-[60%] m-auto h-[max-content] my-10 px-8 pt-10 lg:px-14 relative top-[0px] lg:top-[-400px] pb-10'>
                    <h1 className='h1 text-2xl font-bold lg:text-5xl'>India New Hub of Start-up</h1>
                    <div className='pt-5'>
                        <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">#start up</span>
                        <span className="bg-pink-100 text-pink-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-pink-200 dark:text-pink-900">#bussiness</span>
                        <span className="bg-purple-100 text-purple-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-purple-200 dark:text-purple-900">#entrepreneurship</span>

                    </div>
                    <div className='flex mt-5'>
                        <img class="w-10 h-10 rounded-full " src={img} alt="Rounded avatar" />
                        <span>
                            <h4 className='mx-5 my-0'> Dinesh Khemani</h4>
                            <h2 className='text-gray-400 text-sm ml-4'>16th Nov 2022 Originally Publlish at <a href='/'><span className='text-primary'> ClubIDE.com </span></a>| 12 min read</h2>
                        </span>
                    </div>
                    <div className='pt-10'>
                        <div className='text-base'>
                            India’s Start-up journey is not a recent one, India has already started their journey in 80s and the 90s company like Infosys and Airtel, along with many other Start-ups. <br />
                            In past few years,<b> India played major role in the global start-up ecosystem</b>, along with the US, China, United Kingdom and others.
                            Before understanding the how India become the hub of start-up we need to <b></b>understand about start-up and phases of start-up.</div>

                    </div>

                <div className='pt-5 py-2'>
                    <h1 className='text-2xl font-bold'>1) What is Start-up?</h1>
                </div>
                <div className='pt-5'>
                    <div className='text-base'>
                        The term start-up refers to a company that’s in the initial phase or stage of the business. When there are any problems, entrepreneurs come with the solution, they believe that this problem can be solved and then create the start-up to provide the solution of that problem to the world.
                    </div>
                </div>

                <div className='pt-5 py-2'>
                    <h1 className='text-2xl font-bold'>2) The 4 Phases/Stages of Start-up</h1>
                </div>

                <div className="p-5">
                    <h1 className='text-xl font-bold'>1) Idea</h1>
                    <div className="text-base">The First Start-up phase is evaluating the idea and finding the perfect solution. Also speak with people which can be your target audience.</div>
                    <h1 className='text-xl font-bold mt-5'>2) Launch</h1>
                    <div className="text-base">The next Step in the Start-up is the Launching Phase. This is where entrepreneur turn their idea into reality and make available for public.</div>
                    <h1 className='text-xl font-bold mt-5'>3) Growth</h1>
                    <div className="text-base">Growth Stage is all about the scaling the start-up, after successful launch need to focus on more important tasks like identifying the users/customers and growing the start-up..</div>
                    <h1 className='text-xl font-bold mt-5'>4) Maturity</h1>
                    <div className="text-base">The last stage of start-up is maturity. This is when entrepreneurs should be looking for new opportunities to expand the start-up. This might be looking for acquisition opportunities, investing in team for growth etc.
                        It is very challenging to complete these all 4 stages and become the successful start-up.<br />
                        Now coming back to topic next question is
                    </div>
                </div>


                <div className='pt-5 py-2'>
                    <h1 className='text-2xl font-bold'>3) What is the Position of India in the start-up in world?</h1>
                </div>
                <div className='pt-5'>
                    <div className='text-base'>
                        India is among the top-20 start-up ecosystems globally and has been ranked 19th in the country-wise ranking for 2022 in Global Start-up Ecosystem index.
                        India currently has 43 cities listed in the top 1000, Globally with Bengaluru which is ranked 8th among the world’s top 10 cities.
                        Bengaluru, the capital megacity of Karnataka, has the “Strongest start-up ecosystem in India”.
                        This rank is calculated using sum of three parameters.
                        <ul>
                            <li>1) Quality</li>
                            <li>2) Quantity</li>
                            <li>3) Business Environment.</li>
                        </ul>
                    </div>
                </div>

                <div className='pt-5 py-2'>
                    <h1 className='text-2xl font-bold'>How India become the start-up hub?</h1>
                </div>
                <div className='pt-5'>
                    <div className='text-base'>
                        The government has recognized the value of the start-ups because they can help in economy means creating the innovative solutions for real problems and also creating jobs for peoples.<br />
                        Government also launched website for start-ups <a href="startupindia.gov.in">startupindia.gov.in</a> <br />
                        Government also providing courses for start-ups and giving various benefits to start-ups so people can start start-ups easily and also government encouraging them.<br />
                        Many Colleges and their universities also started to encourage students for start-ups. They help students to <b><u> convert their innovative ideas into start-up also providing the guidance and providing seat-fund related to start-up. </u></b> E.g., GEC Gandhinagar has EC-CELL., GTU Innovation Council – (Innovation to impact).
                        Also, Nowadays Shark tank India Is launched which is initiative by various start-ups founders they listens to entrepreneur’s ideas and then decides to invest their own money to help entrepreneurs and also provide them mentorship support and global platform for their products and service. This initiative can help India to become the top 10 in the global ranking of the start-up world.
                    </div>

                    <div className='pt-5 py-2'>
                        <h1 className='text-2xl font-bold'>5)Top 5 India Unicorns </h1>
                    </div>

                    <div className="p-5">
                        <h1 className='text-xl font-bold'>1) Paytm</h1>
                        <div className="text-base">Paytm is one of the biggest unicorn start-up in India. Paytm is a <b>payment service provider</b> in India it helps users to make easily payment from anywhere to any bank account.</div>
                        <h1 className='text-xl font-bold mt-5'>2) Byju’s classes</h1>
                        <div className="text-base">Byju’s is an <b>education platform</b> located in Bangalore. It is a free + premium = freemium online coaching company which helps student in studying for classes and for various exams like JEE Gujcet etc.</div>
                        <h1 className='text-xl font-bold mt-5'>3) Ola</h1>
                        <div className="text-base">Ola is India’s largest transportation network. It provides the cab ride and car rental services so user can <b> go easily anywhere at any time.</b></div>
                        <h1 className='text-xl font-bold mt-5'>4) Boat</h1>
                        <div className="text-base">Boat is one of the most famous company and it is an India-based electronics brand that sells various products like earphones, watch etc. You can see many students wearing boat neckbands nowadays.
                        </div>
                    </div>

                    <div className='pt-5 py-2'>
                        <h1 className='text-2xl font-bold'>Challenges of start-up and future </h1>
                    </div>

                    <div className="p-5">
                        <h1 className='text-xl font-bold'>1) Insufficiency of talent</h1>
                        <div className="text-base">Although skilled personnel are not quite bad in India, start-ups just cannot afford them all the time <br />
                            So, government also nowadays providing various courses for free also Courses YouTube is already available.
                        </div>
                        <h1 className='text-xl font-bold mt-5'>2) Lack of risk-taking</h1>
                        <div className="text-base">Due to time or financial situations many entrepreneurs don’t take the risk to start the start-up.<br />
                            So, Government and various college and universities like GTU started various programs to help students so they can able to take the risk and start the start-up.
                            .</div>
                        <h1 className='text-xl font-bold mt-5'>3) Financing</h1>
                        <div className="text-base">Often start-up needs funding to run the start-up because at initial stage start-ups incomes are very less. <br />
                            So, Government also started to give the funding to best start-ups also GTU Innovative council provide seed-fund up to 50 Lakhs
                        </div>
                        <h1 className='text-xl font-bold mt-5'>4) Right Mentor</h1>
                        <div className="text-base">Right mentor always plays big role in start-ups without mentorship often young entrepreneur forgot their real goal so mentorship is very much needed in start-ups. <br />
                            That’s why Shark-tank India providing them mentorship and funding both to help the start-up. Also, university and college help with mentorship.

                        </div>
                    </div>

                    <div className='pt-5 py-2'>
                        <h1 className='text-2xl font-bold'>Future of start-ups:</h1>
                    </div>
                    <div className='pt-5'>
                        <div className='text-base'>
                            According to the economic <b>survey 2021-2022, 61,400
                                start-ups</b> are certified by the department for promotion of industry and internal trade (DPIIT). <br />
                            Based on these records we can see how many start-ups are coming to the market every year also governments and various colleges, companies organizing start-ups competitions every year. Winner getting the full support from the organization like funds, mentor etc
                        </div>
                    </div>
                </div>

                <div className='pt-5 py-2'>
                    <h1 className='text-2xl font-bold'>Conclusion:</h1>
                </div>
                <div className='pt-5'>
                    <div className='text-base'>
                        If you have any idea which can solve the any problem of the common people facing in day-to-day life. And u just need to believe that this idea will change the world so believe in yourself and start contributing so India can become the global start-ups hub in the world.
                    </div>
                </div>
                </div>
                <Share_Blog />
            </div>
        </>
    )
}
