import React from 'react'
import img from '../../imgs/Blog_Imgs/Author_Imgs/demo.png'

import Ecell9 from '../../imgs/Blog_Imgs/Post_Imgs/Ecell-9.png'
import Ecell9_1 from '../../imgs/Blog_Imgs/Post_Imgs/Ecell_9_1.png'
import Ecell9_2 from '../../imgs/Blog_Imgs/Post_Imgs/Ecell_9_2.png'
import Ecell9_3 from '../../imgs/Blog_Imgs/Post_Imgs/Ecell_9_3.png'
import Ecell9_4 from '../../imgs/Blog_Imgs/Post_Imgs/Ecell_9_4.png'
import ScrollToTop from '../ScrollToTop'

import Share_Blog from './Share_Blog';

export default function Ecell_27th_Dec() {
    return (
        <>
        <ScrollToTop />
            <div className='container pt-28 w-max m-auto '>
                <div className="py-5 ">
                    <h1 className="mb-20 pb-5 border-b-4 border-yellow text-6xl font-bold w-max m-auto text-primary">Blog Details</h1>
                </div>
                <img src={Ecell9} className="scale-[0.8] w-[98.5vw] h-auto lg:scale-[1]" alt="..." />
            </div>
            <div>
                <div className='countiner bg-white border-2 border-gray-100 shadow-lg w-[90%] lg:w-[60%] m-auto h-[max-content] my-10 px-8 pt-10 lg:px-14 relative top-[0px] lg:top-[-400px] pb-10'>
                    <h1 className='h1 text-2xl font-bold lg:text-5xl'>How to Start a Startup in India</h1>
                    <div className='pt-5'>
                        <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">#innovation</span>
                        <span className="bg-pink-100 text-pink-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-pink-200 dark:text-pink-900">#effectiveness</span>
                        <span className="bg-purple-100 text-purple-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-purple-200 dark:text-purple-900">#efficiency</span>

                    </div>
                    <div className='flex mt-5'>
                        <img class="w-10 h-10 rounded-full " src={img} alt="Rounded avatar" />
                        <span>
                            <h4 className='mx-5 my-0'>Jenish Sakariya</h4>
                            <h2 className='text-gray-400 text-sm ml-4'>27th Dec 2022 Originally Publlish at <a href='/'><span className='text-primary'> ClubIDE.com </span></a>| 12 min read</h2>
                        </span>
                    </div>


                    <div className='pt-10'>
                        <div className='text-base'>
                            Every startup is a dream, before turning into a reality and to make the reality
                            worthwhile, it is important to know about & understand its very perspectives and
                            then plan on achieving the bigger goal
                        </div>
                    </div>
                    <div>
                        <img src={Ecell9_1} className="center m-2" width="85%" height="85%"></img>
                    </div>
                    <div className='text-base'>
                        When you look to make it out on your own and ask your-self the way how to start a
                        startup in India, having an extremely better concept is simply the beginning, and
                        right here are a few steps you may take to not only assist you in conceptualising an
                        amazing enterprise concept but also successfully executing it.
                    </div>

                    <div className='pt-5 py-2'>
                        <h1 className='text-2xl font-bold'>Here are a few steps on How to Start a Startup?</h1>
                    </div>
                    <div>
                        <div className='text-base'>
                            <h1 className='text-xl font-bold my-2'>1.Carry out market research to base on your ideas:</h1>
                            Every entrepreneur has a minimum one massive concept for the subsequent
                            massive issues, however what subjects is not always the concept but the
                            willingness to do so on that concept so one can make that concepts right into a
                            reality. For this, you want to conduct thorough marketplace studies, especially for a
                            start-up in India, so one can understand where you stand and in case your concept
                            is well worth pursuing further. <br /><br />
                            For your subsequent step determine what number of humans being face those
                            issues that your own commercial enterprise concept will solve.
                        </div>
                        <div>
                            <img src={Ecell9_2} className="center m-2" width="85%" height="85%"></img>
                        </div>
                    </div>

                    <div>
                        <div className='text-base'>
                            <h1 className='text-xl font-bold my-2'>2. Analyse your ideas:</h1>
                            After vigorous market researches, you may now know about the product you want
                            to work on, the Audience you want to target, and much more. But before all that,
                            you must analyse your ideas. To explain literally, you must check the odds of the
                            idea’s feasibility for the present & future. Times, as they have become, should be
                            suitable for your product’s long-term survival. And it should be noted that your
                            product must be bringing some solution to a long-suffering problem.
                        </div>

                    </div>
                    <div>
                        <div className='text-base'>
                            <h1 className='text-xl font-bold my-2'>3. Make a business plan:</h1>
                            When you ask yourself the way to begin a startup and the way to execute it, one of
                            the most difficult issues for it is applying a marketing strategy. It is one of the
                            maximum critical steps for any organisation and taught to each issue of your
                            organisation and its destiny. <br /><br />
                            You can apply an amazing marketing strategy for any organisation using the
                            marketplace research (which you did earlier) and from there on grow your plan for
                            destiny and draft each step in your organisation. This marketing strategy may also
                            assist you to decide on dreams and milestones in your organisation and assist you
                            to recognize while you move off target and manual you on many aspects.
                        </div>

                    </div>

                    <div>
                        <div className='text-base'>
                            <h1 className='text-xl font-bold my-2'>4. Managing Funds:</h1>
                            For any business, capital is one of the most important elements in its launch and
                            raising. it is one of the toughest elements for any organisation.
                        </div>
                        <div>
                            <img src={Ecell9_3} className="center m-2" width="55%" height="55%"></img>
                        </div>
                        <div className='text-base'>
                            While capital funding is big for any corporation it additionally does have a darkish facet
                            as it is a big monetary threat for anyone. Thus, you want to recognize precisely how
                            much capital you want to elevate in your commercial enterprise and what kind of it
                            must come from buyers as a loan. Once you have got that figured out, you must
                            determine how you will be elevating it and the way you propose to provide it again in
                            the future.
                        </div>

                    </div>

                    <div>
                        <div className='text-base'>
                            <h1 className='text-xl font-bold my-2'>5. Intellectual Property Rights and laws:</h1>
                            When you asking yourself how to start a startup, it is also important to check if you can
                            start or not. If the research is not already patented or trademarked by any other
                            company, you will need to secure intellectual property rights. A company or individual
                            can own the intellectual property rights to create and sell any product if they hold
                            intellectual property or IP rights <br /><br />
                            As early as possible, secure your IP rights to prevent copycats. As a result, you’ll also
                            know whether your product is genuine & isn’t violating any existing intellectual property
                            rights. This is very important for a startup in India.
                        </div>

                    </div>

                    <div>
                        <div className='text-base'>
                            <h1 className='text-xl font-bold my-2'>6.Find your office spaces:</h1>
                            It is more important to execute a business plan after you have developed one, and
                            to do so well, so during this process find an office where you and your colleagues
                            can not only sit and execute the plan but also as a startup, your work environment
                            is also very important, as you are getting off the ground and need to be motivated.
                        </div>

                    </div>

                    <div>
                        <div className='text-base'>
                            <h1 className='text-xl font-bold my-2'>7.Create a wide digital buzz:</h1>
                            Digital media or marketing is the king of this digital era. Advertising, branding,
                            promoting, marketing. All of this buzz comes under the one umbrella. And all the
                            companies, whether small scale or an MNC, have chosen their own stream of
                            digital media that not just make their product available worldwide but also gives
                            them a wider & better experience of reaching out to people from their home,
                            country and providing them with their best valuable service.
                        </div>
                        <div>
                            <img src={Ecell9_4} className="center m-2" width="55%" height="55%"></img>
                        </div>
                        <div className='text-base'>
                            With the right SEO practice and analysis, you can find out how much your social
                            media engagement is working and where you need to work and become a
                            professional player, with all these factors the base of a startup will be long-term.
                        </div>

                    </div>



                </div>
                <Share_Blog />

            </div>
        </>
    )
}
