import '../App.css';
import Course from '../Components/Home_Page_Components/Course';
import Hero from '../Components/Home_Page_Components/Hero';
import Members from '../Components/Home_Page_Components/Members';
import Student_Activities from '../Components/Home_Page_Components/Student_Activities'
import Sponser from '../Components/Home_Page_Components/Sponsers';
import BackAnimations from '../Components/BackAnimations';
import ScrollToTop from '../Components/ScrollToTop';






function HomePage() {
  return (
    <>
  <ScrollToTop />
  {/* <BackAnimations /> */}
  <Hero />
  <Members />
  <Course />
  <Student_Activities />
  <Sponser />

  </>
  );
}

export default HomePage;
