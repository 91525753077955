import './App.css';
import HomePage from './Page/HomePage';
import Navbar from './Components/Navbar';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import AboutUs from './Components/Aboutus';
import Blogpage from './Page/Blogpage';
import Discord from './Components/Home_Page_Components/Discord';
import Footer from './Components/Footer';
import Activities_Page from './Page/Activities_Page'
import Eventpage from './Page/EventPage/EventPage'
import GECG from './Components/GECG';
import Sept21_Blog1 from './Components/Blogs/Sept21_Blog1';
import Sept21_Blog2 from './Components/Blogs/Sept21_Blog2'
import Nov21_Blog2 from './Components/Blogs/Nov21_Blog2'
import Mar22_Blog1 from './Components/Blogs/Mar22_Blog1';
import Mar22_Blog2 from './Components/Blogs/Mar22_Blog2';
import Feb22_Blog1 from './Components/Blogs/Feb22_Blog1';
import Feb22_Blog2 from './Components/Blogs/Feb22_Blog2';
import Jan22_Blog1 from './Components/Blogs/Jan22_Blog1';
import Jan22_Blog2 from './Components/Blogs/Jan22_Blog2';
import Dec21_Blog1 from './Components/Blogs/Dec21_Blog1';
import Dec21_Blog2 from './Components/Blogs/Dec21_Blog2';
import Nov21_Blog1 from './Components/Blogs/Nov21_Blog1';
import Oct21_Blog1 from './Components/Blogs/Oct21_Blog1';
import Oct21_Blog2 from './Components/Blogs/Oct21_Blog2';
import Apr22_Blog2 from './Components/Blogs/Apr22_Blog2';
import Apr22_Blog1 from './Components/Blogs/Apr22_Blog1';
import PreLoader from './Components/PreLoader';
import { useEffect, useState } from 'react';
import ScrollToTop from "react-scroll-to-top";
import Team2021 from './Page/Team_Page/Team2021';
import Team2022 from './Page/Team_Page/Team2022';
import July22_Blog1 from './Components/Blogs/July22_Blog1';
import July22_Blog2 from './Components/Blogs/July22_Blog2';
import Aug22_Blog1 from './Components/Blogs/Aug22_Blog1';
import Aug22_Blog2 from './Components/Blogs/Aug22_Blog2';
import Sept22_Blog1 from './Components/Blogs/Sept22_Blog1';
import Newevent from './Page/EventPage/Newevent';

import Ecell_2nd_Nov from './Components/Blogs/Ecell_1';
import Ecell_9th_Nov from './Components/Blogs/Ecell_2';
import Ecell_16th_Nov from './Components/Blogs/Ecell_3';
import Ecell_23rd_Nov from './Components/Blogs/Ecell_4';
import Ecell_30th_Nov from './Components/Blogs/Ecell_5';
import Ecell_7th_Dec from './Components/Blogs/Ecell_6';
import Ecell_14th_Dec from './Components/Blogs/Ecell_7';
import Ecell_21st_Dec from './Components/Blogs/Ecell-8';
import Ecell_27th_Dec from './Components/Blogs/Ecell-9';
import Attendace_Application from './Components/Application';

function App() {
  const [ Loading, setLoading ] = useState(true);

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 400)
  }, []);

  return (
    <>
      {
        Loading ?
          <PreLoader />
          :
          <>
            <BrowserRouter>
              <GECG />
              <Navbar />
              <ScrollToTop color='#182a5900' width='40'  smooth />
              <Routes>
                <Route path='/' element={<HomePage />} />
                <Route path='/activities' element={<Activities_Page />} />
                <Route path='/team' element={<Team2022 />} />
                <Route path='/2021' element={<Team2021 />} />
                <Route path='/2022' element={<Team2022 />} />
                <Route path='/aboutus' element={<AboutUs />} />
                <Route path='/blogpage' element={<Blogpage />} />
                <Route path='/blogpage/Ecell_27th_Dec' element={<Ecell_27th_Dec />} />
                <Route path='/blogpage/Ecell_21st_Dec' element={<Ecell_21st_Dec />} />
                <Route path='/blogpage/Ecell_14th_Dec' element={<Ecell_14th_Dec />} />
                <Route path='/blogpage/Ecell_7th_Dec' element={<Ecell_7th_Dec />} />
                <Route path='/blogpage/Ecell_30th_Nov' element={<Ecell_30th_Nov />} />
                <Route path='/blogpage/Ecell_23rd_Nov' element={<Ecell_23rd_Nov />} />
                <Route path='/blogpage/Ecell_16th_Nov' element={<Ecell_16th_Nov />} />
                <Route path='/blogpage/Ecell_9th_Nov' element={<Ecell_9th_Nov />} />
                <Route path='/blogpage/Ecell_2nd_Nov' element={<Ecell_2nd_Nov />} />
                <Route path='/blogpage/1092022' element={<Sept22_Blog1 />} />
                <Route path='/blogpage/2082022' element={<Aug22_Blog2 />} />
                <Route path='/blogpage/1082022' element={<Aug22_Blog1 />} />
                <Route path='/blogpage/2072022' element={<July22_Blog2 />} />
                <Route path='/blogpage/1072022' element={<July22_Blog1 />} />
                <Route path='/blogpage/1042022' element={<Apr22_Blog1 />} />
                <Route path='/blogpage/2042022' element={<Apr22_Blog2 />} />
                <Route path='/blogpage/1032022' element={<Mar22_Blog1 />} />
                <Route path='/blogpage/2032022' element={<Mar22_Blog2 />} />
                <Route path='/blogpage/1022022' element={<Feb22_Blog1 />} />
                <Route path='/blogpage/2022022' element={<Feb22_Blog2 />} />
                <Route path='/blogpage/1012022' element={<Jan22_Blog1 />} />
                <Route path='/blogpage/2012022' element={<Jan22_Blog2 />} />
                <Route path='/blogpage/1122021' element={<Dec21_Blog1 />} />
                <Route path='/blogpage/2122021' element={<Dec21_Blog2 />} />
                <Route path='/blogpage/1112021' element={<Nov21_Blog1 />} />
                <Route path='/blogpage/2112021' element={<Nov21_Blog2 />} />
                <Route path='/blogpage/1102021' element={<Oct21_Blog1 />} />
                <Route path='/blogpage/2102021' element={<Oct21_Blog2 />} />
                <Route path='/blogpage/1092021' element={<Sept21_Blog1 />} />
                <Route path='/blogpage/2092021' element={<Sept21_Blog2 />} />


                <Route path='/eventpage' element={<Eventpage />} />
                <Route path = '/eventpage/newevent' element = {<Newevent />} />
                <Route path = '/download_Applcation' element = {<Attendace_Application />} />


              </Routes>
            </BrowserRouter>

            <Discord />
            <Footer />


          </>



}
  </>
  );
}

export default App;
