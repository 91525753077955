import React from 'react'
import img from '../../imgs/Blog_Imgs/Author_Imgs/Sakshi Shah.jpg'

import demo from '../../imgs/Blog_Imgs/Post_Imgs/aug22_1.png'
import Share_Blog from './Share_Blog';
import img1 from '../../imgs/In_Blog_Imgs/aug22_blog1.jpg'
import ScrollToTop from '../ScrollToTop';


export default function Aug22_Blog1() {
  return (
    <>
    <ScrollToTop />
      <div className='container pt-28 w-max m-auto '>
        <div className="py-5 ">
          <h1 className="mb-20 pb-5 border-b-4 border-yellow text-6xl font-bold w-max m-auto text-primary">Blog Details</h1>
        </div>
        <img src={demo} className="scale-[0.8] w-[98.5vw] h-auto lg:scale-[1]" alt="..." />
      </div>
      <div>
        <div className='countiner bg-white border-2 border-gray-100 shadow-lg w-[90%] lg:w-[60%] m-auto h-[max-content] my-10 px-8 pt-10 lg:px-14 relative top-[0px] lg:top-[-400px] pb-10'>
          <h1 className='h1 text-2xl font-bold lg:text-5xl'>HOW TO BEAT SELF-DOUBT AND NEGATIVITY?</h1>
          <div className='pt-5'>
            <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">#beconfident</span>
            <span className="bg-pink-100 text-pink-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-pink-200 dark:text-pink-900">#staypositive</span>
            <span className="bg-purple-100 text-purple-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-purple-200 dark:text-purple-900">#beoptimistic</span>
            <span className='"bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900"'>#progress</span>
          </div>
          <div className='flex mt-5'>
            <img class="w-10 h-10 rounded-full " src={img} alt="Rounded avatar" />
            <span>
              <h4 className='mx-5 my-0'>Sakshi Shah</h4>
              <h2 className='text-gray-400 text-sm ml-4'>~CE Batch-2k24 | 5 min read</h2>
            </span>
          </div>
          <div className='pt-5'>
            <div className='text-base'>
            There are some days when we feel that there is no hope or no purpose in our life. Even we come up with questions like whatever we are doing is it really even worth it? Do we really make a difference in our life? Are our efforts enough to fulfill our future requirements and needs of ourselves and our family? These questions are obvious when we are in competition with so many better people around us. Also, if these questions come up in your mind, it itself proves that you are actually making an effort.
            </div>
          </div>
          <div className='pt-5'>
            <div className="flex flex-wrap md:flex-nowrap lg:flex-nowrap">
              <div className='text-img'>
              Self-doubt arises even though we are confident about our work when we compare ourselves with others. Now that is a different thing that comparison also gives us the motivation to do better. But this comparison should not lead us to doubt ourselves. The solution to this is, that we should learn from the people better than us rather than compare. Our ultimate goal should be to make better versions of ourselves. If you are doing better than you were last year, you are progressing.<br></br><br></br>

              Even at a slow rate, progress is progress. Also, there are times when the result of our hard-work is not up to the mark. In such cases, considering the failure as a stepping stone and having the satisfaction that we tried at least is the best choice. But this betterment should be consistent, only then it will lead to greater results. This was about self-doubt.
              </div>
              <div className="image">
                <img src={img1} alt="Couldn't load this image..." ></img>
              </div>
            </div>
          </div>
          <div className='pt-5'>
            <div className='text-base'>
            Negativity is another thing that many of us face. There are many ways through which negativity can be kept afar. The three best ways which I think are the most effective are:
            </div>
          </div>
          <div className='pt-5'>
            <div className='text-base'>
            1) Writing down/Realizing Gratitude for at least three things/person/incidents or even for our own self every day. Being grateful for small things helps us to be happy on the days when major tasks do not go as planned.
            </div>
          </div>
          <div className='pt-5'>
            <div className='text-base'>
            2) Listening to your mind and heart or simply following your own instincts i.e. doing the tasks that actually matter. Sometimes being overly relaxed and carefree leads to negativity. Once we do the tasks for which we were procrastinating, we are automatically inclined towards positivity.
            </div>
          </div>
          <div className='pt-5'>
            <div className='text-base'>
            3) Doing any task of your choice that you enjoy and which brings you peace without any distractions (such as phones or pressure about work.) It can be meditation/exercise/doing a hobby/spending time in nature or with loved ones.
            </div>
          </div>
          <div className='pt-5'>
            <div className='text-base'>
            Along with above-listed ways, of course, there are individual perspectives of people so they deal with negativity in their own way. When these tasks will become a routine, you will not even realize that you are pursuing optimism.
            </div>
          </div>
          <div className='pt-5'>
            <div className='text-base text-center inline-block text-[20px] ml-0 lg:mx-10'><blockquote>You live most of your life inside your head, make sure it is a nice place to be!</blockquote>
            </div>
          </div>
        </div>

        <Share_Blog />
       </div>




    </>
  )
}
