import React from 'react'
import img from '../../imgs/Blog_Imgs/Author_Imgs/Adrija Chaudhary.jpg'

import demo from '../../imgs/Blog_Imgs/Post_Imgs/july22_2.jpg'
import ScrollToTop from '../ScrollToTop';
import Share_Blog from './Share_Blog';

export default function July22_Blog2() {
  return (
    <>
    <ScrollToTop />
      <div className='container pt-28 w-max m-auto '>
        <div className="py-5 ">
          <h1 className="mb-20 pb-5 border-b-4 border-yellow text-6xl font-bold w-max m-auto text-primary">Blog Details</h1>
        </div>
        <img src={demo} className="scale-[0.8] w-[98.5vw] h-auto lg:scale-[1]" alt="..." />
      </div>
      <div>
        <div className='countiner bg-white border-2 border-gray-100 shadow-lg w-[90%] lg:w-[60%] m-auto h-[max-content] my-10 px-8 pt-10 lg:px-14 relative top-[0px] lg:top-[-400px] pb-10'>
          <h1 className='h1 text-2xl font-bold lg:text-5xl'>CODING? NAH JUST PLAYING A GAME</h1>
          <div className='pt-5'>
            <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">#coding</span>
            <span className="bg-pink-100 text-pink-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-pink-200 dark:text-pink-900">#programming</span>
            <span className="bg-purple-100 text-purple-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-purple-200 dark:text-purple-900">#focus</span>
            <span className='"bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900"'>#developerlife</span>
          </div>
          <div className='flex mt-5'>
            <img class="w-10 h-10 rounded-full " src={img} alt="Rounded avatar" />
            <span>
              <h4 className='mx-5 my-0'>Adrija Choudhury</h4>
              <h2 className='text-gray-400 text-sm ml-4'>~EC Batch-2k25 | 5 min read</h2>
            </span>
          </div>
          <div className='pt-20'>
            <div className='text-base'>
            Hey readers! This article is basically to guide beginners who have just begun with coding, to make their journey easy and smooth. Here I'm going to prove it with 5 points how coding is as simple as playing a game.
            </div>
          </div>
          <div className='pt-5 py-2'>
            <h1 className='text-2xl font-bold'>1. FOCUS ON THE FUNDAMENTALS</h1>
          </div>
          <div className='pt-5'>
            <div className='text-base'>
            Focusing on the fundamentals is same as going through the introduction of a game, learning the play buttons, the key points of a game and the features of the game. So while you start to code get an overview of all the functions, softwares and shortcuts where, when and how to use them.
            </div>
          </div>
          <div className='pt-5 py-2'>
            <h1 className='text-2xl font-bold'>2. LEARN TO ASK FOR HELP</h1>
          </div>
          <div className='pt-5'>
            <div className='text-base'>
            Everyone of us know that we have help option where we can seek answers to our problems not all but most of them. Just in case if you are too lazy to read through the help menu you can always Google it or ask your friend, it's as simple as asking for help in between a game or ordering food online.
            </div>
          </div>
          <div className='pt-5 py-2'>
            <h1 className='text-2xl font-bold'>3. BE PATIENT AND CHECK ERROR POINTS</h1>
          </div>
          <div className='pt-5'>
            <div className='text-base'>
            Either you're playing a game of you're coding remember the tips and the key points where you can make a mistake, always remember it or keep a note of it somewhere.
            </div>
          </div>
          <div className='pt-5 py-2'>
            <h1 className='text-2xl font-bold'>4. DO MORE THAN JUST READING THE SAMPLE CODE</h1>
          </div>
          <div className='pt-5'>
            <div className='text-base'>
            Watching gaming videos is as simple as reading the codes but only practical application would help you get a grip over it. Until you don't try it on your own and pass through the obstacles you won't understand the language or the game.
            </div>
          </div>
          <div className='pt-5 py-2'>
            <h1 className='text-2xl font-bold'>5. CHECK OUT HELPFUL ONLINE CODING RESOURCES</h1>
          </div>
          <div className='pt-5'>
            <div className='text-base'>
                <u>
            <div ul>
                <li><a href='https://www.codecademy.com/?g_network=g&g_device=c&g_adid=528849219286&g_keyword=codecademy&g_acctid=243-039-7011&g_adtype=search&g_adgroupid=70492864474&g_keywordid=kwd-41065460761&g_campaign=INTL_Brand_Exact&g_campaignid=1726903838&utm_term=codecademy&utm_campaign=&utm_content=528849219286&utm_id=t_kwd-41065460761:ag_70492864474:cp_1726903838:n_g:d_c&utm_source=google&utm_medium=paid-search&utm_term=codecademy&utm_campaign=&utm_content=528849219286&gclid=Cj0KCQjw_viWBhD8ARIsAH1mCd5ShZhxXLgLq7fHmmbVqeZJXNCZzyIb3U-BJoozKOeJI84T50iCVnYaAm0OEALw_wcB'>Codecademy</a></li>
                <li><a href='https://www.khanacademy.org/'>Khan Academy</a></li>
                <li><a href='https://www.codeavengers.com/'>Code Avengers</a></li>
                <li><a href='https://www.geeksforgeeks.org/'>GeeksforGeeks</a></li>
                <li><a href='https://developer.mozilla.org/en-US/'>Mozilla Developer Network</a></li>
                <li><a href='https://www.pluralsight.com/codeschool'>Code School</a></li>
                <li><a href='https://teamtreehouse.com/?utm_source=google&utm_medium=cpc&utm_campaign=Brand&utm_term=treehouse&gclid=Cj0KCQjw_viWBhD8ARIsAH1mCd47bTImGSVPyG2nquxb2ho5nIGImoP0gnOvwzDTLXf8yMLzJKyZUH8aAppaEALw_wcB'>Treehouse</a></li>
                <li><a href='https://www.udacity.com/?utm_campaign=920638067_c&utm_keyword=udacity_e&utm_medium=ads&utm_source=gsem_india_brand&utm_term=63844644663_in'>Udacity</a></li>
            </div>
            </u>
            </div>
          </div>

        </div>

        <Share_Blog />
       </div>




    </>
  )
}
