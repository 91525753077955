import React, { useState } from "react";
import { app } from './firebase'
import { storage } from "./firebase";
import { ref, uploadBytes } from "firebase/storage";

export default function SubmitModal(props) {
  const [showModal, setShowModal] = React.useState(false);
  const [fileUpload, setFileUpload] = useState(null);
  var today = new Date(),
  date = today.getDate() + '-' + (today.getMonth() + 1) + '-' +today.getFullYear() ;
  var today = new Date(),
  time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();

  const [user, setUser] = useState({
    name: "",
    email: "",
    Branch: "",
    semester: "",
    Date: date

  });



  let name, value;

  const getUserData = (event) => {
    name = event.target.name;
    value = event.target.value;

   setUser({ ...user, [name]: value })
  }

  const postData = async (e) => {

    e.preventDefault();

    const { name, email, Branch, semester} = user;
    if ((name && email && Branch && semester && fileUpload != null)) {
      const res = await fetch("https://club-ide-default-rtdb.firebaseio.com/BlogSubmitData.json",
      {
        method: "POST",
        headers: {
          "Content-Type": "applicaton/json",
        },
        body: JSON.stringify({
            name,
            email,
            Branch,
            semester,
            date
          }),
        }
        );
        const fileRef = ref(storage, `Blogs/${user.name + ` ` + date + ` ` + time + ` ` + fileUpload.name}`);
      uploadBytes(fileRef, fileUpload).then(() => {

      })
        if (res) {
          setUser({
            name: "",
            email: "",
            Branch: "",
            semester: "",

          });
          alert("Blog Submitted Successfully 👍");
        }
      }
      else {
        if (fileUpload == null) alert('Please upload a file 😁');
        else{
        alert('Please Fill the Data 😁');
      }
      }



    };

    return (
      <>
      <div className="inline-flex w-full overflow-hidden shadow sm:w-auto sm:mx-2 mb-10">
        <button
          className="inline-flex items-center justify-center w-full px-10 py-3 rounded text-base button-site"
          type="button"
          onClick={() => setShowModal(true)}
          >
          {props.text}
        </button>
      </div>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none mt-20" data-aos="zoom-in">
            <div className="relative my-6 mx-auto w-[90%] lg:w-[25%] md:w-[70%]">
              {/*content*/}
              <div className="rounded-[54px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex justify-center flex-col pt-5">
                  <h3 className="text-[30px] font-semibold center text-primary uppercase">Submit your Blog</h3>

                  <h2 className="center py-5 text-sm text-gray-400">Publish your passions, Inspire the members  </h2>
                </div>
                {/*body*/}
                <div className="mt-2 text-center">
                  <form method="POST" className="flex flex-col justify-center items-center">
                    <input
                      type="text"
                      name="name"
                      className="border-2 bg-[#DBE2E2]  w-[80%] h-10 rounded-6 p-5 placeholder:font-semibold my-3 shadow-sm shadow-gray-500 font-semibold text-gray-600 rounded-full"
                      placeholder="Name"
                      value={user.name}
                      onChange={getUserData}
                      autoComplete="off"
                      required={true}
                    />
                    <input
                      type="email"
                      name="email"
                      className="border-2 bg-[#DBE2E2]  w-[80%] h-10 rounded-6 p-5 placeholder:font-semibold my-3 shadow-sm shadow-gray-500 font-semibold text-gray-600 rounded-full"
                      placeholder="Email"
                      value={user.email}
                      onChange={getUserData}
                    />
                    <select name="Branch" value={user.Branch} onChange={getUserData} className="border-2  w-[80%] h-10 px-4 bg-[#DBE2E2] rounded-6 my-3 shadow-sm shadow-gray-500 font-semibold text-gray-600 rounded-full" required={true}>
                      <option value="0">Branch</option>
                      <option value="CE">CE</option>
                      <option value="IT">IT</option>
                      <option value="BM">BM</option>
                      <option value="EC">EC</option>
                      <option value="IC">IC</option>
                      <option value="CIVIL">CIVIL</option>
                      <option value="Other">Other</option>
                    </select>
                    <select name="semester" value={user.semester} onChange={getUserData} className="border-2  w-[80%] h-10 px-4 bg-[#DBE2E2] rounded-6 my-3 shadow-sm shadow-gray-500 font-semibold text-gray-600 rounded-full" required={true}>
                      <option value="0">Semester</option>
                      <option value="1">
                        1<sup>st</sup>
                      </option>
                      <option value="2">
                        2<sup>nd</sup>
                      </option>
                      <option value="3">
                        3<sup>rd</sup>
                      </option>
                      <option value="4">
                        4<sup>th</sup>
                      </option>
                      <option value="5">
                        5<sup>th</sup>
                      </option>
                      <option value="6">
                        6<sup>th</sup>
                      </option>
                      <option value="7">
                        7<sup>th</sup>
                      </option>
                      <option value="8">
                        8<sup>th</sup>
                      </option>
                    </select>
                    <input name="file" onChange={(event => (setFileUpload(event.target.files[0])))} class="border-2 w-[80%] h-11 px-4 bg-[#DBE2E2] rounded-6 my-3 shadow-sm shadow-gray-500 font-semibold text-gray-600 rounded-full" id="formFileLg" type="file" required={true} />
                    {/*footer*/}
                    <div className="text-sm">Please Send your Blog in Given Format</div>
                    <a href="https://docs.google.com/document/u/1/d/1ryCA37cbQnoZchACvKLKzFS7OZm6ZYSQ/edit?usp=sharing&ouid=116325818393111671970&rtpof=true&sd=true" target='_blank'><span className=" text-blue-600">Click Here </span>to Show Blog Format</a>
                    <div className="flex justify-between my-5">
                      <button
                        className="button-site uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => setShowModal(false)}
                      >
                        Close
                      </button>


                      <button
                        type="submit"
                        className="uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 button-site"
                        onClick={postData}

                      >Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

    </>
  );
}