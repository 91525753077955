import React from 'react'
import img from '../../imgs/Blog_Imgs/Author_Imgs/demo.png'

import Ecell8 from '../../imgs/Blog_Imgs/Post_Imgs/Ecell-8.jpg'
import Ecell8_1 from '../../imgs/Blog_Imgs/Post_Imgs/Ecell_8_1.jpg'
import ScrollToTop from '../ScrollToTop';
import Share_Blog from './Share_Blog';

export default function Ecell_21st_Dec() {
    return (
        <>
        <ScrollToTop />
            <div className='container pt-28 w-max m-auto '>
                <div className="py-5 ">
                    <h1 className="mb-20 pb-5 border-b-4 border-yellow text-6xl font-bold w-max m-auto text-primary">Blog Details</h1>
                </div>
                <img src={Ecell8} className="scale-[0.8] w-[98.5vw] h-auto lg:scale-[1]" alt="..." />
            </div>
            <div>
                <div className='countiner bg-white border-2 border-gray-100 shadow-lg w-[90%] lg:w-[60%] m-auto h-[max-content] my-10 px-8 pt-10 lg:px-14 relative top-[0px] lg:top-[-400px] pb-10'>
                    <h1 className='h1 text-2xl font-bold lg:text-5xl'>START UP With Different view</h1>
                    <div className='pt-5'>
                        <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">#Bussiness</span>
                        <span className="bg-pink-100 text-pink-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-pink-200 dark:text-pink-900">#start up</span>

                    </div>
                    <div className='flex mt-5'>
                        <img class="w-10 h-10 rounded-full " src={img} alt="Rounded avatar" />
                        <span>
                            <h4 className='mx-5 my-0'>Shreyanshi Bhatt</h4>
                            <h2 className='text-gray-400 text-sm ml-4'>21st Dec 2022 Originally Publlish at <a href='/'><span className='text-primary'> ClubIDE.com </span></a>| 12 min read</h2>
                        </span>
                    </div>
                    <div className='pt-5 py-2'>
                        <h1 className='text-2xl font-bold'>What is a Start-Up</h1>
                    </div>
                    <div>
                        <div className='text-base'>
                            The term start-up refers to a company in the initial stages of
                            operations. Start-ups are established by one or more entrepreneurs
                            who want to develop a product or service for which they believe there
                            is demand. The goal of a start-up is to create something new or
                            improve something that currently exists. Start-ups are risky and
                            require a lot of time and effort, however, once successful, the output
                            is astounding.
                        </div>

                    </div>
                    <div className='pt-5 py-2'>
                        <h1 className='text-2xl font-bold'>How Does a Start-up Work</h1>
                    </div>
                    <div>
                        <div className='text-base'>
                            PS: I am a huge fan of Start-Ups and Shark Tank☺. Watching
                            amazing ideas and businesses always made me curious about how
                            Start-Ups actually work. So, let’s learn about the functioning of Start-
                            Ups. <br /><br />
                            On a high level, a start-up works like any other company. A group of
                            employees work together to create a product that customer will buy.
                            What distinguishes a startup from other businesses, though, is the way
                            a start-up goes about doing that. <br /><br />
                            Regular companies duplicate what’s been done before. A subsequent
                            restaurant owner may franchise an existing restaurant. That is, they
                            work from an existing guideline of how a business should work. <br /><br />
                            A start-up aims to create a brand new template. In the food domain,
                            that may mean offering meal kits, like Blue Apron or Dinnerly, to
                            provide the same thing as restaurants—a meal prepared by a chef—
                            but with convenience and choice that sit-down places cannot match.
                            In turn, this delivers a scale individual restaurants can’t touch: tens of
                            millions of prospective customers, instead of thousands.<br /><br />
                            Traditional businesses do not experiment with their business model,
                            whereas Start-Up companies build and modify their business model
                            along the way. Unlike the old school businesses, start-ups have
                            flexible, agile and efficient work schedules.
                        </div>
                    </div>


                    <div className='pt-5 py-2'>
                        <h1 className='text-2xl font-bold'>Types</h1>
                    </div>

                    <div className="p-5">
                        <h1 className='text-xl font-bold'>1) Small business start-ups.</h1>
                        <div className="text-base">Created by regular people -Self-funded -have a great site but not an app -eg.travel agents, bakers, etc..</div>
                        <h1 className='text-xl font-bold mt-5'>2) Lifestyle start-ups.</h1>
                        <div className="text-base">-Creators live by doing what they love -eg.dancer, chef, etc.</div>
                        <h1 className='text-xl font-bold mt-5'>3) Buyable start-ups.</h1>
                        <div className="text-base">made from scratch but are up for sale to bigger companies.</div>
                        <h1 className='text-xl font-bold mt-5'>4) Big business start-ups.</h1>
                        <div className="text-base">-Large companies -consistent innovation required
                        </div>
                    </div>

                    <div className='pt-5 py-2'>
                        <h1 className='text-2xl font-bold'>Some Important terms Related to start-ups</h1>
                    </div>

                    <div className="p-5">
                        <h1 className='text-xl font-bold'>1)Angel Investor.</h1>
                        <div className="text-base">An angel investor is someone who gives the first funding to a start-up.</div>
                        <h1 className='text-xl font-bold mt-5'>2) Bootstrapping.</h1>
                        <div className="text-base">When a start-up is bootstrapping, it's self-funded. More than 80% of start-ups start out through bootstrapping.</div>

                        <h1 className='text-xl font-bold mt-5'>3) Dragon</h1>
                        <div className="text-base">A dragon is a rare start-up that raises $1 billion in a single round of funding. Uber is an example of a dragon start-up.</div>
                        <h1 className='text-xl font-bold mt-5'>4) Freemium</h1>
                        <div className="text-base">A freemium model is a popular choice for start-ups. It refers to offering customers a restricted version of a product or service for free or at fewer prices with more advanced options available at extra cost.
                        </div>
                        <h1 className='text-xl font-bold mt-5'>4) Valuation</h1>
                        <div className="text-base">Valuation refers to how much your company is worth, it is determined in two ways: pre and post-money valuation. <br /><br />
                          <b>Pre-money valuation:</b> This is an estimate of how valuable your
                            company is before you obtain any funding. <br/><br/>
                           <b>Post-money valuation:</b>  This is how much your company is valued
                            after a round of funding plus the pre-money valuation.
                        </div>
                    </div>

                    <div className='pt-5 py-2'>
                        <h1 className='text-2xl font-bold'>How to build a start-up: </h1>
                    </div>
                    <div>
                            <img src={Ecell8_1} className="center m-2" width="45%" height="45%"></img>
                        </div>
                    <div>
                        <div className='text-base'>
                        <h2 className='text-2xl font-bold'>7 Steps to Building a Start-up from Scratch</h2>
                        <ul className='list-decimal px-5 py-2'>
                            <li>Find a good idea</li>
                            <li>Develop a business plan</li>
                            <li>Ensure you have adequate capital</li>
                            <li>Choose the right people</li>
                            <li>Pick a location and design a site</li>
                            <li>Learn how to leverage digital marketing techniques</li>
                            <li>Create your customer base</li>
                        </ul>
                        </div>
                    </div>

                    <div className='pt-5 py-2'>
                        <h1 className='text-2xl font-bold'>Advantages: -  </h1>
                    </div>
                    <div>
                        <div className='text-base'>
                        <ul className=' list-decimal px-5 py-2'>
                            <li>Agility</li>
                            <li>Efficiency (Lean and Mean)</li>
                            <li>Team Culture</li>
                            <li>Personalization</li>
                            <li>Versatility</li>
                            <li>Flexibility</li>
                            <li>Fun</li>
                        </ul>
                        </div>
                    </div>


                    <div className='pt-5 py-2'>
                        <h1 className='text-2xl font-bold'>Disadvantages: -  </h1>
                    </div>
                    <div>
                        <div className='text-base'>
                        <ul className=' list-decimal px-5 py-2'>
                            <li>Risk</li>
                            <li>Compensation</li>
                            <li>Market Access</li>
                            <li>Team Composition</li>
                            <li>Resources</li>
                            <li>Processes</li>
                            <li>Stress</li>
                        </ul>
                        </div>
                    </div>


                    <div className='pt-5 py-2'>
                        <h1 className='text-2xl font-bold'>Some examples of Start-ups: -  </h1>
                    </div>
                    <div>
                        <div className='text-base'>
                        <ul className=' list-decimal px-5 py-2'>
                            <li>Airbnb</li>
                            <li>Instagram</li>
                            <li>Pinterest</li>
                            <li>LinkedIn</li>
                        </ul>
                        </div>
                    </div>
                </div>

                <Share_Blog />
            </div>
        </>
    )
}
