import React from 'react'
import img from '../../imgs/Blog_Imgs/Author_Imgs/demo.png'

import Ecell2 from '../../imgs/Blog_Imgs/Post_Imgs/Ecell-2.jpg'
import Ecell2_1 from '../../imgs/Blog_Imgs/Post_Imgs/Ecell_2_1.png'
import Ecell2_2 from '../../imgs/Blog_Imgs/Post_Imgs/Ecell_2_2.png'
import Ecell2_3 from '../../imgs/Blog_Imgs/Post_Imgs/Ecell_2_3.png'
import ScrollToTop from '../ScrollToTop'
import Share_Blog from './Share_Blog';

export default function Ecell_9th_Nov() {
    return (
        <>
        <ScrollToTop />
            <div className='container pt-28 w-max m-auto '>
                <div className="py-5 ">
                    <h1 className="mb-20 pb-5 border-b-4 border-yellow text-6xl font-bold w-max m-auto text-primary">Blog Details</h1>
                </div>
                <img src={Ecell2} className="scale-[0.8] w-[98.5vw] h-auto lg:scale-[1]" alt="..." />
            </div>
            <div>
                <div className='countiner bg-white border-2 border-gray-100 shadow-lg w-[90%] lg:w-[60%] m-auto h-[max-content] my-10 px-8 pt-10 lg:px-14 relative top-[0px] lg:top-[-400px] pb-10'>
                    <h1 className='h1 text-2xl font-bold lg:text-5xl'>Is innovation required?</h1>
                    <div className='pt-5'>
                        <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">#innovation</span>
                        <span className="bg-pink-100 text-pink-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-pink-200 dark:text-pink-900">#effectiveness</span>
                        <span className="bg-purple-100 text-purple-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-purple-200 dark:text-purple-900">#efficiency</span>

                    </div>
                    <div className='flex mt-5'>
                        <img class="w-10 h-10 rounded-full " src={img} alt="Rounded avatar" />
                        <span>
                            <h4 className='mx-5 my-0'>Palak Srivastav</h4>
                            <h2 className='text-gray-400 text-sm ml-4'>9th Nov 2022 Originally Publlish at <a href='/'><span className='text-primary'> ClubIDE.com </span></a>| 12 min read</h2>
                        </span>
                    </div>
                    <div className='pt-10'>
                        <div className='text-base'>
                            We must first understand the true definition of innovation, so what exactly is an innovation?
                            Is it something no one has ever considered, or something they could never imagine
                            coexisting with them?
                        </div>
                    </div>
                    <div className='pt-5'>
                        <div className='text-base'>
                            Innovation is the design, development, and deployment of a new product, process, or
                            service with the goal of increasing efficiency, effectiveness, or competitive advantage. When
                            people begin to think outside the box, when they cannot see themselves adjusting in the
                            boundaries set by the world and mediocre minds, they begin to innovate.
                        </div>
                    </div>
                    <div>
                        <img src={Ecell2_1} className="center m-2" width="85%" height="85%"></img>
                    </div>

                    <div className='pt-5 py-2'>
                        <h1 className='text-2xl font-bold'>How do you become an innovator? What is the most important way to stand out from the
                            crowd?</h1>
                    </div>
                    <div className='pt-5'>
                        <div className='text-base'>
                            The fundamental characteristic of great innovators and businesses is they see something
                            that others do not. They don't just listen to what people say; they create something new,
                            something you didn't know you needed, but the moment you see it, you think, 'I should
                            definitely have that.
                        </div>
                    </div>

                    <div className='pt-5 py-2'>
                        <h1 className='text-2xl font-bold'>What distinguishing characteristics does an innovator possess?</h1>
                    </div>

                    <div className="imagebox flex flex-wrap md:flex-nowrap lg:flex-nowrap">
                        <div className='text-img'>
                            <ul className='uppercase text-green-600 font-bold list-disc'>
                                <li>Strategic thinking</li>
                                <li>Being inventive and willing to experiment.</li>
                                <li>Making mistakes and trying again.</li>
                                <li>They value collaboration and progress, and they dislike the past because they know the new is on the way.</li>
                            </ul>
                            <br />
                        </div>
                    </div>


                    <div className='pt-5 py-2'>
                        <h1 className='text-2xl font-bold'>Guidelines for Becoming a More Effective Innovator</h1>
                    </div>
                    <div className='pt-5'>
                        <div className='text-base'>
                            The ability to think outside the box from all angles, to try new things, and to be willing to
                            take risks are essential for becoming a determined innovator. We must awaken the inner
                            child, a child within who is filled with ideas and creativity, who cares less about others and
                            more about how they can help them Above all, enjoy what you do and never lose faith in
                            your abilities.
                        </div>
                    </div>

                    <div className='pt-5 py-2'>
                        <h1 className='text-2xl font-bold'>Is being innovative a skill?</h1>
                    </div>
                    <div className='pt-5'>
                        <div className='text-base'>
                            Innovation is arguably the most important skill to have, especially if you want to cross-
                            market your skills and advance in your career. Every industry requires creativity and
                            leadership qualities, so you must be innovative if you want to be resourceful, in demand,
                            and happily employed. The best part about being an innovator is that it is a skill that is
                            learned rather than inherited.
                        </div>
                        <img src={Ecell2_2} className="center m-2" width="85%" height="85%"></img>

                        <div className='text-base'>
                            Every day, we create something new, whether it's baking a cake in a cooker when the oven
                            is broken or making use of the translucency of plastic bottles as a replacement for bulbs in
                            villages.<br />
                            India is well-known for its <b>global contributions</b>, which includes:
                        </div>

                        <ul className='uppercase text-green-600 font-bold list-disc m-2'>
                            <li>The zero concepts</li>
                            <li>Facial Reconstruction in Plastic Surgery</li>
                            <li>Weights: A measurement system</li>
                            <li>Chess: Originally played by four people</li>
                            <li>Cotton: Natural fibres and cultivation</li>
                            <li>Yoga: Mind-body connection</li>
                            <li>A board game called Snakes and Ladders</li>
                            <li>Sanskrit: One of the oldest and purest languages, and the source of many sub-languages</li>
                            <li>Ayurveda: A system of medicine based on the use of herbs and shrubs</li>
                        </ul>
                    </div>

                    <div className='pt-5 py-2'>
                        <h1 className='text-2xl font-bold'>Conclusion:</h1>
                    </div>
                    <img src={Ecell2_3} className="center m-2" width="85%" height="85%" />                    <div className='pt-5'>
                        <div className='text-base'>
                            Innovation is a basic human trait; what distinguishes a <b> good innovator </b> is that he or she has
                            good observation on problems, works on them, irrespective of what others say, and, most
                            importantly, sees success in every failure they encounter.
                        </div>
                    </div>
                </div>
                <Share_Blog />

            </div>




        </>
    )
}
