import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage"

const firebaseConfig = {
  apiKey: "AIzaSyD1UoKd9gqP0xeq_dH7yJciPOBWl8EFsDc",
  authDomain: "club-ide.firebaseapp.com",
  databaseURL: "https://club-ide-events-default-rtdb.firebaseio.com",
  projectId: "club-ide",
  storageBucket: "club-ide.appspot.com",
  messagingSenderId: "896819209182",
  appId: "1:896819209182:web:b9bc958e2feb9d7b41f34f"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app)
export const db = getFirestore(app);
