import React from 'react'
import img from '../../imgs/Blog_Imgs/Author_Imgs/Jinal Raval.jpg'

import demo from '../../imgs/Blog_Imgs/Post_Imgs/sept22_1.png'
import ScrollToTop from '../ScrollToTop';
import Share_Blog from './Share_Blog';

export default function Sept22_Blog1() {
  return (
    <>
    <ScrollToTop />
      <div className='container pt-28 w-max m-auto '>
        <div className="py-5 ">
          <h1 className="mb-20 pb-5 border-b-4 border-yellow text-6xl font-bold w-max m-auto text-primary">Blog Details</h1>
        </div>
        <img src={demo} className="scale-[0.8] w-[100vw] h-[100vh] lg:scale-[1]" alt="..." />
      </div>
      <div>
        <div className='countiner bg-white border-2 border-gray-100 shadow-lg w-[90%] lg:w-[60%] m-auto h-[max-content] my-10 px-8 pt-10 lg:px-14 relative top-[0px] lg:top-[-400px] pb-10'>
          <h1 className='h1 text-2xl font-bold lg:text-5xl'>BHAGAVAD GITA FOR LIFE...</h1>
          <div className='pt-5'>
            <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">#bhagavadgita</span>
            <span className="bg-pink-100 text-pink-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-pink-200 dark:text-pink-900">#karma</span>
            <span className="bg-purple-100 text-purple-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-purple-200 dark:text-purple-900">#knowledge</span>
            <span className='"bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900"'>#shlokas</span>
          </div>
          <div className='flex mt-5'>
            <img class="w-10 h-10 rounded-full " src={img} alt="Rounded avatar" />
            <span>
              <h4 className='mx-5 my-0'>Jinal Raval</h4>
              <h2 className='text-gray-400 text-sm ml-4'>~CE Batch-2k24 | 5 min read</h2>
            </span>
          </div>
          <div className='pt-5'>
            <div className='text-base'>
            Below are mentioned shlokas from the Bhagavad Gita, where I picked 3 slokas that generate loads of impact in students' life.
            </div>
          </div>
          <div className='pt-5'>
            <div className='text-base text-center inline-block text-[20px] ml-0 lg:mx-10'><blockquote>कर्मण्येवाधिकारस्तेमा फलेषुकदाचन |<br></br>
          मा कर्मफलहेतुर्भूर्मातेसङ्गोऽस्त्वकर्मणि ||</blockquote>
            </div>
          </div>
          <div className='pt-5'>
            <div className='text-base'>
            <b>Translation:</b><br></br>You have a right to perform your prescribed duties, but you are not entitled to the fruits of your actions. Never consider yourself to be the cause of the results of your activities, nor be attached to inaction.
            </div>
          </div>
          <div className='pt-5'>
            <div className='text-base'>
            <b>Meaning:</b><br></br>This is probably one of the most famous slokas from the Bhagavad Gita. It offers deep insight into the proper spirit of work and is often quoted whenever the topic of karma yog is discussed. The verse gives four instructions regarding the science of work:
            <div ol>
            <li>Do your duty, but do not concern yourself with the results.</li>
            <li>The fruits of your actions are not for your enjoyment.</li>
            <li>Even while working, give up the pride of doership.</li>
            <li>Do not be attached to inaction.</li>
            </div>

            </div>
          </div>
          <div className='pt-5'>
            <div className='text-base'>
            This is what students are taught from childhood (if you are from India) “Karm Karo, Fal ki Chinta mat Karo”. It simply means, do not worry about the result too much, just concentrate on your action to achieve the goal. Because actions are known to us and give us surety if we want to do it well or not. However, results are not in our hands and they are uncertain. It depends on multiple factors, for example, the situation, other people who are involved, etc. It can not depend solely on the doer. If we develop a result-oriented mind, then if the result is not up to our expectations, pain is certain to occur. Also, do not attach yourself to inaction (निष्क्रियता) because sometimes when the work is hard and burdensome, we resort to inaction. So never lose interest in what you do.
            </div>
          </div>
          <div className='pt-5'>
            <div className='text-base text-center inline-block text-[20px] ml-0 lg:mx-10'><blockquote>न जायतेम्रियतेवा कदाचि <br></br>
            नायं भूत्वा भविता वा न भूय: |<br></br>
            अजो नित्य: शाश्वतोऽयं पुराणो<br></br>
            न हन्यतेहन्यमानेशरीरे ||</blockquote>
            </div>
          </div>
          <div className='pt-5'>
            <div className='text-base'>
            <b>Translation:</b><br></br>The soul is neither born nor does it ever die; nor having once existed, does it ever cease to be. The soul is without birth, eternal, immortal, and ageless. It is not destroyed when the body is destroyed.
            </div>
          </div>
          <div className='pt-5'>
            <div className='text-base'>
            <b>Meaning:</b><br></br>We must eliminate the fear of death as it might sometimes create hindrance in taking risks or in whatever we wanted to do in life. It also helps to discover the inner self (which you may call “soul”) and find a true purpose of life.
            </div>
          </div>
          <div className='pt-5'>
            <div className='text-base text-center inline-block text-[20px] ml-0 lg:mx-10'><blockquote>मात्रास्पर्शास्तुकौन्तेय शीतोष्णसुखदु: खदा: |<br></br> आगमापायिनोऽनित्यास्तांस्तितिक्षस्व भारत ||</blockquote>
            </div>
          </div>
          <div className='pt-5'>
            <div className='text-base'>
            <b>Translation:</b><br></br>O son of Kunti, the contact between the senses and the sense objects gives rise to fleeting perceptions of happiness and distress. These are non-permanent and come and go like the winter and summer seasons. O descendant of Bharat, one must learn to tolerate them without being disturbed.
            </div>
          </div>
          <div className='pt-5'>
            <div className='text-base'>
            <b>Meaning:</b><br></br>The absolute meaning of this shloka is “nothing is permanent in this world”. Seasons come and go in nature. Likewise, pain and pleasure are also temporary. Tough time does not stay for long. The bad phase passes after a certain period. Learn to tolerate them without being affected by them.
            </div>
          </div>
        </div>

        <Share_Blog />
       </div>




    </>
  )
}
