import React from 'react'
import img from '../../imgs/Blog_Imgs/Author_Imgs/Viveena Rathi.jpg'

import demo from '../../imgs/Blog_Imgs/Post_Imgs/july22_1.png'
import ScrollToTop from '../ScrollToTop';
import Share_Blog from './Share_Blog';

export default function July22_Blog1() {
  return (
    <>
    <ScrollToTop />
      <div className='container pt-28 w-max m-auto '>
        <div className="py-5 ">
          <h1 className="mb-20 pb-5 border-b-4 border-yellow text-6xl font-bold w-max m-auto text-primary">Blog Details</h1>
        </div>
        <img src={demo} className="scale-[0.8] w-[98.5vw] h-auto lg:scale-[1]" alt="..." />
      </div>
      <div>
        <div className='countiner bg-white border-2 border-gray-100 shadow-lg w-[90%] lg:w-[60%] m-auto h-[max-content] my-10 px-8 pt-10 lg:px-14 relative top-[0px] lg:top-[-400px] pb-10'>
          <h1 className='h1 text-2xl font-bold lg:text-5xl'>HOW TO BE CONSISTENT?</h1>
          <div className='pt-5'>
            <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">#consistency</span>
            <span className="bg-pink-100 text-pink-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-pink-200 dark:text-pink-900">#motivation</span>
            <span className="bg-purple-100 text-purple-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-purple-200 dark:text-purple-900">#goals</span>
            <span className='"bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900"'>#hardwork</span>
          </div>
          <div className='flex mt-5'>
            <img class="w-10 h-10 rounded-full " src={img} alt="Rounded avatar" />
            <span>
              <h4 className='mx-5 my-0'>Viveena Rathi</h4>
              <h2 className='text-gray-400 text-sm ml-4'>~CE Batch-2k24 | 5 min read</h2>
            </span>
          </div>
          <div className='pt-20'>
            <div className='text-base'>
            To be consistent, you have to replicate positive behavior or performance day after day, until it defines you.
            </div>
          </div>
          <div className='pt-5 py-2'>
            <h1 className='text-2xl font-bold'>1. ISOLATE ONE GOAL</h1>
          </div>
          <div className='pt-5'>
            <div className='text-base'>
            Developing consistency goes against human nature. It's burdensome, especially at first. It takes a lot of energy, a finite resource. Don't make the process even more challenging by trying to do too much all at once... The trick is to pick one goal to focus on at a time. What's your biggest issue? What's your most damaging inconsistency? Start there. Work at it. Stabilize. Then move on to your next goal.
            </div>
          </div>
          <div className='pt-5 py-2'>
            <h1 className='text-2xl font-bold'>2. FOCUS ON INCREMENTAL IMPROVEMENT</h1>
          </div>
          <div className='pt-5'>
            <div className='text-base'>
            You're not going to develop a positive, worthwhile habit overnight. Our brains don't work that way. In fact, new research declares that it could take months to permanently change your behavior. Months. That's a lot of patience and focus. That's a lot of work. Allow yourself to notice and celebrate the small wins, the incremental improvements you achieve along the way. They'll keep you motivated.
            </div>
          </div>
          <div className='pt-5 py-2'>
            <h1 className='text-2xl font-bold'>3. FIGHT YOUR EMOTIONS</h1>
          </div>
          <div className='pt-5'>
            <div className='text-base'>
            The brain is a taxing organ. It uses a lot of energy, which is why we sometimes feel mentally sluggish or lazy, especially when faced with a challenge, like consistency. Sometimes we really are tired. Sometimes, though, it's a biological trick... Don't fall for it. Push forward. Fight your emotions. If you don't, you'll stay stuck.
            </div>
          </div>
          <div className='pt-5 py-2'>
            <h1 className='text-2xl font-bold'>4. FORGIVE YOUR FAILURES</h1>
          </div>
          <div className='pt-5'>
            <div className='text-base'>
            That said, you are human, imperfect like the rest of us ... As you pursue your consistent goals, you will get tired. You will stumble. Whatever your goal may be, you're bound to misstep from time to time - and that's okay. “Slipping on your habits doesn't make you a failure,” writes consistency expert, James Clear. “It makes you normal. What separates top performers from everyone else is that they get back on track quickly.”
            </div>
          </div>
          <div className='pt-5'>
            <div className='text-base'>
            It usually takes 3 weeks of consistently doing something to make a new habit. Every three weeks, set a small goal to attain in that time period. Don't tackle too much. Start with small rituals and build your way up.
            </div>
          </div>
          <div className='pt-5'>
            <div className='text-base'>
            Set boundaries for your commitments and personal relationships. Build your willpower. Consistency requires willpower to achieve, because consistency tends to lean towards performing even when you don't feel like it. To do that, you need to have the willpower to do it.
            </div>
          </div>
        </div>

        <Share_Blog />
       </div>




    </>
  )
}
