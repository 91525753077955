import React from 'react'
import img from '../../imgs/Blog_Imgs/Author_Imgs/Jay Prajapati.jpg'

import demo from '../../imgs/Blog_Imgs/Post_Imgs/aug22_2.jpg'
import Share_Blog from './Share_Blog';
import img1 from '../../imgs/In_Blog_Imgs/aug22_blog2_1.webp'
import ScrollToTop from '../ScrollToTop';

export default function Blog_card() {
  return (
    <>
    <ScrollToTop />
      <div className='container pt-28 w-max m-auto '>
        <div className="py-5 ">
          <h1 className="mb-20 pb-5 border-b-4 border-yellow text-6xl font-bold w-max m-auto text-primary">Blog Details</h1>
        </div>
        <img src={demo} className="scale-[0.8] w-[98.5vw] h-auto lg:scale-[1]" alt="..." />
      </div>
      <div>
        <div className='countiner bg-white border-2 border-gray-100 shadow-lg w-[90%] lg:w-[60%] m-auto h-[max-content] my-10 px-8 pt-10 lg:px-14 relative top-[0px] lg:top-[-400px] pb-10'>
          <h1 className='h1 text-2xl font-bold lg:text-5xl'>CARBON : A NEW PROGRAMMING LANGUAGE</h1>
          <div className='pt-5'>
            <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">#newtechnology</span>
            <span className="bg-pink-100 text-pink-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-pink-200 dark:text-pink-900">#programming</span>
            <span className="bg-purple-100 text-purple-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-purple-200 dark:text-purple-900">#programminglanguage</span>
            <span className='"bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900"'>#coding</span>
          </div>
          <div className='flex mt-5'>
            <img class="w-10 h-10 rounded-full " src={img} alt="Rounded avatar" />
            <span>
              <h4 className='mx-5 my-0'>Jay Prajapati</h4>
              <h2 className='text-gray-400 text-sm ml-4'>~CE Batch-2k24 | 5 min read</h2>
            </span>
          </div>
          <div className='pt-5'>
            <div className='text-base'>
            Recently, CPP north event 2022 was held and basically, it is an event where many developers come together to discuss future developments of C++. So, in this event, a Googler named Chandler Carruth introduced developers to a new programming language called Carbon.
            </div>
          </div>
          <div className='pt-5'>
            <div className="flex flex-wrap md:flex-nowrap lg:flex-nowrap">
              <div className='text-img'>
              <b>So, what's wrong with C++?</b><br></br><br></br>
              The general criticism of C++ is that it leans towards being overly complex. IntelliSense generally sucks, no support for first-class functions and tuples and initializer lists are considered a 'hack'. In addition to this, there are a few quirks like duplicate syntax and operators such as the & being both a logical operator and a reference. There's a bag full of other problems but in short, C++ works but it has its issues.
              </div>
              <div className="image">
                <img src={img1} alt="Couldn't load this image..." ></img>
              </div>
            </div>
          </div>
          <div className='pt-5'>
            <div className='text-base'>
            <b>So, when's the full release?</b>
            </div>
          </div>
          <div className='pt-5'>
            <div className='text-base'>
            Currently, Carbon is in an experimental phase. The current roadmap is as follows:
            </div>
          </div>
          <div className='pt-5'>
            <div className='text-base'>
            <div ul>
                <li>Release of a core working version (0.1) by end of 2022</li>
                <li>0.2 in 2023</li>
                <li>Full 1.0 release in 2024-2025</li>
            </div>
            </div>
          </div>
          <div className='pt-5'>
            <div className='text-base'>
            You could also learn more about carbon and get involved in it as it is open source on its <a href='https://github.com/carbon-language/carbon-lang'><u>official GitHub page</u></a>.
            </div>
          </div>

        </div>

        <Share_Blog />
       </div>




    </>
  )
}
