import React from 'react'
import img from '../../imgs/Blog_Imgs/Author_Imgs/demo.png'

import Ecell6 from '../../imgs/Blog_Imgs/Post_Imgs/Ecell-6.jpg'
import ScrollToTop from '../ScrollToTop';
import Share_Blog from './Share_Blog';

export default function Ecell_7th_Dec() {
    return (
        <>
        <ScrollToTop />
            <div className='container pt-28 w-max m-auto '>
                <div className="py-5 ">
                    <h1 className="mb-20 pb-5 border-b-4 border-yellow text-6xl font-bold w-max m-auto text-primary">Blog Details</h1>
                </div>
                <img src={Ecell6} className="scale-[0.8] w-[98.5vw] h-auto lg:scale-[1]" alt="..." />
            </div>
            <div>
                <div className='countiner bg-white border-2 border-gray-100 shadow-lg w-[90%] lg:w-[60%] m-auto h-[max-content] my-10 px-8 pt-10 lg:px-14 relative top-[0px] lg:top-[-400px] pb-10'>
                    <h1 className='h1 text-2xl font-bold lg:text-5xl'>Why It's Important to Say NO in Corporate World !!</h1>
                    <div className='pt-5'>
                        <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">#Bussiness</span>
                        <span className="bg-pink-100 text-pink-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-pink-200 dark:text-pink-900">#Corporate world</span>
                        <span className="bg-purple-100 text-purple-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-purple-200 dark:text-purple-900">#Saying No</span>

                    </div>
                    <div className='flex mt-5'>
                        <img class="w-10 h-10 rounded-full " src={img} alt="Rounded avatar" />
                        <span>
                            <h4 className='mx-5 my-0'>Purvi Gadariya</h4>
                            <h2 className='text-gray-400 text-sm ml-4'>7th Dec 2022 Originally Publlish at <a href='/'><span className='text-primary'> ClubIDE.com </span></a>| 12 min read</h2>
                        </span>
                    </div>
                    <div className='pt-10'>
                        <div className='text-base mt-2'>
                            "NO" just two simple letters and a one simple word but when it comes to say
                            it's automatically becomes difficult. For some people, NO is packed with guilt. It is
                            said that, "We cannot make everyone happy" that means sometimes saying NO is
                            beneficial for your own growth and mental health. When you are at your workspace or
                            in corporate world then it's essential to say NO to someone.</div>
                    </div>
                    <div className='text-base mt-2'>
                        As we have lived in an environment where saying NO is a bad habit... in that
                        case saying NO is being more difficult for us. But sometimes saying NO is important
                        no matter what the reasons are! Someone has well said," Nobody knows your limits
                        better than you. " So when you feel like that the given work is not for you then listen
                        to your intuitions and take decision further.</div>
                    <div className='text-base mt-2'>
                        "Your time is limited so don't waste it living in someone else's life." When you
                        are at your workspace and you have a lot of to do then you say NO is situations'
                        demand. It is important to say NO when you don't want to do that work, it is important
                        to say NO when you have some more important work than that, it is important to say
                        NO when you just want to say NO</div>
                    <div className='text-base mt-2'>

                        "Saying NO at workspace can save your time for yourself. It is foremost to
                        spend some time with yourself. It is beneficial for knowing yourself , your skills and
                        your need. Investing your time on you is better option than wasting it on someone
                        else.<br /><br />
                        It is also noticeable thing that how you are saying NO. Saying NO is also a type
                        of soft skill. There will be many situations when you have to say no without ruin your
                        relations with your boss and colleagues. Learning how to say NO nicely will show your
                        coworkers and boss that you aren't saying no because you don't want to help them,
                        but rather because you are deluge and cannot take on more responsibility at the
                        movement.<br /><br />
                        Some of us have fear of being unfriend or being out of fraternity if they say NO.
                        It's not necessary to be nice in front of everyone. Remember "No one can play your
                        role better than you." You know that from which phase you have passed or still you
                        are passing. If you think that just saying NO is enough for them for breaking up with
                        you then it's totally wrong. Your comfort stands first before everything.
                        NO is a decision, YES is a responsibility. Learning how to say NO will make
                        your overall work quality and mental health improve, and make you a true team
                        player. Saying NO at work may be uncomfortable but saying YES to everything is not
                        healthy either. Keeping track your priorities will help you determine if you can take
                        on extra tasks. If you see that you have multiple priorities and that taking on
                        anymore will cause you to become swamp, then say NO. <br /><br />
                        You always want to provide a sound reason , not an excuse, as to why you're
                        saying NO. This helps the other person see things from your point of view respond
                        more rationally rather than reactively. Never lie about your reasons that why you are
                        saying NO, this will make your colleagues less likely believe you. Always try to make
                        your real and positive image. <br /><br />
                        Saying YES all the time may lead to the waste of time. Your time is as precious
                        as your money so invest it in good things. You'll have some more personal space to
                        enjoy your life if you start saying NO to unnecessary things.<br /><br />
                        "Saying NO Politely is Hundred Times Better Than Saying YES with Bitterness."</div>




                </div>

                <Share_Blog />
            </div>
        </>
    )
}
