import React from 'react'
import img from '../../imgs/Blog_Imgs/Author_Imgs/demo.png'

import Ecell5 from '../../imgs/Blog_Imgs/Post_Imgs/Ecell-5.jpg'
import ScrollToTop from '../ScrollToTop';
import Share_Blog from './Share_Blog';

export default function Ecell_30th_Nov() {
    return (
        <>
        <ScrollToTop />
            <div className='container pt-28 w-max m-auto '>
                <div className="py-5 ">
                    <h1 className="mb-20 pb-5 border-b-4 border-yellow text-6xl font-bold w-max m-auto text-primary">Blog Details</h1>
                </div>
                <img src={Ecell5} className="scale-[0.8] w-[98.5vw] h-auto lg:scale-[1]" alt="..." />
            </div>
            <div>
                <div className='countiner bg-white border-2 border-gray-100 shadow-lg w-[90%] lg:w-[60%] m-auto h-[max-content] my-10 px-8 pt-10 lg:px-14 relative top-[0px] lg:top-[-400px] pb-10'>
                    <h1 className='h1 text-2xl font-bold lg:text-5xl'>Top 5 Business Startup Online Without Money For Beginners</h1>
                    <div className='pt-5'>
                        <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">#Bussiness</span>
                        <span className="bg-pink-100 text-pink-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-pink-200 dark:text-pink-900">#Online Bussiness</span>
                        <span className="bg-purple-100 text-purple-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-purple-200 dark:text-purple-900">#startup</span>

                    </div>
                    <div className='flex mt-5'>
                        <img class="w-10 h-10 rounded-full " src={img} alt="Rounded avatar" />
                        <span>
                            <h4 className='mx-5 my-0'>Karm Patel</h4>
                            <h2 className='text-gray-400 text-sm ml-4'>30th Nov 2022 Originally Publlish at <a href='/'><span className='text-primary'> ClubIDE.com </span></a>| 12 min read</h2>
                        </span>
                    </div>
                    <div className='pt-10'>
                        <div className='text-base mt-2'>
                            In the present situation, people and students do not have money so that they can fulfill the needs of their families. In this situation, people are also unemployed. <br />
                            We can develop any skill in this time so that we can fulfill our needs by business startup. Today many people are fulfilling their needs by start business online.</div>

                    </div>

                    <div className='pt-5 py-2'>
                        <h1 className='text-2xl font-bold'>Quick Tips for business startup for beginners</h1>
                    </div>
                    <div className='p-5'>
                        <ul className='uppercase font-bold list-decimal'>
                            <li>Learn Before You Earn</li>
                            <li>Beware Of Getting Rich Scam</li>
                            <li>Consistency Beats Intensity</li>
                            <li>Focus On Win-Win Strategies</li>
                            <li>Start Now</li>
                        </ul>
                    </div>

                    <div className='pt-5 py-2'>
                        <h1 className='text-2xl font-bold'>Freelancing</h1>
                    </div>
                    <div>
                        <div className='text-base'>
                            Freelancing, you can develop any skill like Video-Editor, Photo-Editor, Web-Designer, Logo-Designer, etc. There are many skills which you can develop and work on the freelancing platform. <br /><br />
                            There are many platforms for freelancing where you can earn income by working for other people. There are many platforms for freelancing but
                            <a href="https://www.fiverr.com/" className='text-green-600 font-bold underline'> Fivver </a>,<a href="https://www.freelancer.in/" className='text-green-600 font-bold underline'>Freelancer </a>,<a href="https://www.upwork.com/" className='text-green-600 font-bold underline'> Upwork </a> , and simplyhired websites that make the most money
                        </div>

                    </div>



                    <div className='pt-5 py-2'>
                        <h1 className='text-2xl font-bold'>Sell Websites and Domains</h1>
                    </div>
                    <div>
                        <div className='text-base'>
                            At this time every people and company are making their website online, so you should buy such profitable domains, for which people can buy from us by purchasing. It takes a lot of time to search for a good domain.<br /> <br />
                            Many websites sell & buy domains and websites, which <a href="https://flippa.com" className='text-green-600 font-bold underline'> Flippa </a>  is a good website where you can sell. For this, you will learn how to transfer a domain and website from one account to another.
                        </div>
                    </div>

                    <div className='pt-5 py-2'>
                        <h1 className='text-2xl font-bold'>Social Marketing</h1>
                    </div>
                    <div>
                        <div className='text-base'>
                            As a College Student, you can earn by connecting with people of other countries from social platforms like Facebook and Instagram.
                        </div>
                    </div>

                    <div className='pt-5 py-2'>
                        <h1 className='text-2xl font-bold'>Facebook Marketing</h1>
                    </div>
                    <div>
                        <div className='text-base'>
                            You must have seen such ads, which you must have searched that content many times before, then all this happens from many platforms like Facebook Ads, Google Ads, etc. You can start business by running ads for others.<br /><br />
                            From Facebook, you can connect with the shopkeeper of other countries, you can increase their income by running ads of their shop in that area, in return, they will give you money. You can also create their shop page for them.

                        </div>
                    </div>

                    <div className='pt-5 py-2'>
                        <h1 className='text-2xl font-bold'>Instagram Marketing</h1>
                    </div>
                    <div>
                        <div className='text-base'>
                            On Instagram also you can work for your clients like Facebook. You can handle their Instagram. You can start business from them by increasing the number of followers in their accounts.
                        </div>
                    </div>

                    <div className='pt-5 py-2'>
                        <h1 className='text-2xl font-bold'>Making Chatbots</h1>
                    </div>
                    <div>
                        <div className='text-base'>
                            Chatbot means that when you go to other's website, you can chat online on it, but earlier, someone in front had to stay online to answer, but now the device itself does automatic chat in front, No person has to stay online.<br /><br />
                            It can be used on many sites and apps. You can make this with coding knowledge or any other way. You can start business by selling it to other people

                        </div>
                    </div>

                    <div className='pt-5 py-2'>
                        <h1 className='text-2xl font-bold'>Affiliate Marketing</h1>
                    </div>
                    <div>
                        <div className='text-base'>
                            Affiliate Marketing means that if someone buys a product from your online affiliate link within 24 hours of clicking on that link, then you get a little commission for it.<br /><br />
                            You want to start Affiliate Marketing, then you can first start with <a href="https://affiliate-program.amazon.in/" className='text-green-600 font-bold underline'> Amazon Affiliate Program </a>.  With this, you can use an affiliate program of clickbank, Flipkart. You should start Amazon Affiliate before that, understand its guidelines, otherwise, your Affiliate Account may also be suspended.

                        </div>
                    </div>

                    <div className='pt-5 py-2'>
                        <h1 className='text-2xl font-bold'>Bonus Tips for How to Make Money Online for Beginners</h1>
                    </div>
                    <div className='p-5'>
                        <ul className='uppercase font-bold list-decimal'>
                            <li>Don’t Quit Existing Job</li>
                            <li>Research for 2 Weeks</li>
                            <li>After Research Narrow it Down and Select One for earn money in India</li>
                            <li>Execute it When You are free</li>
                            <li>Work 6 Hours a Week</li>
                            <li>Work 6 Hours a Week</li>
                            <li>Take a Gap & Re-strategize</li>
                            <li>Repeated Step 5 & 6</li>
                        </ul>
                    </div>

                    <div className='pt-5 py-2'>
                        <h1 className='text-2xl font-bold'>Conclusion</h1>
                    </div>
                    <div>
                        <div className='text-base'>
                            The purpose of this blog was only that you get some knowledge about startup business in India so that you can start your Online Start-Up Journey. I am going to bring further blogs on all these topics.

                        </div>
                    </div>

                </div>

                <Share_Blog />
            </div>
        </>
    )
}
