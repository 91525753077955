import React from 'react'
import img from '../../imgs/Blog_Imgs/Author_Imgs/demo.png'

import Ecell4 from '../../imgs/Blog_Imgs/Post_Imgs/Ecell-4.jpg'
import Ecell4_1 from '../../imgs/Blog_Imgs/Post_Imgs/Ecell_4_1.png'
import Ecell4_2 from '../../imgs/Blog_Imgs/Post_Imgs/Ecell_4_2.png'
import ScrollToTop from '../ScrollToTop'
import Share_Blog from './Share_Blog';

export default function Ecell_23rd_Nov() {
    return (
        <>
        <ScrollToTop />
            <div className='container pt-28 w-max m-auto '>
                <div className="py-5 ">
                    <h1 className="mb-20 pb-5 border-b-4 border-yellow text-6xl font-bold w-max m-auto text-primary">Blog Details</h1>
                </div>
                <img src={Ecell4} className="scale-[0.8] w-[98.5vw] h-auto lg:scale-[1]" alt="..." />
            </div>
            <div>
                <div className='countiner bg-white border-2 border-gray-100 shadow-lg w-[90%] lg:w-[60%] m-auto h-[max-content] my-10 px-8 pt-10 lg:px-14 relative top-[0px] lg:top-[-400px] pb-10'>
                    <h1 className='h1 text-2xl font-bold lg:text-5xl'>JAVA JACKETS</h1>
                    <div className='pt-5'>
                        <span className="bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">#cup</span>
                        <span className="bg-pink-100 text-pink-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-pink-200 dark:text-pink-900">#Hot cup</span>
                        <span className="bg-purple-100 text-purple-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-purple-200 dark:text-purple-900">#cooffe</span>

                    </div>
                    <div className='flex mt-5'>
                        <img class="w-10 h-10 rounded-full " src={img} alt="Rounded avatar" />
                        <span>
                            <h4 className='mx-5 my-0'> Aashutosh Verma</h4>
                            <h2 className='text-gray-400 text-sm ml-4'>23rd Nov 2022 Originally Publlish at <a href='/'><span className='text-primary'> ClubIDE.com </span></a>| 12 min read</h2>
                        </span>
                    </div>
                    <div className='pt-10'>
                        <h1 className='text-2xl font-bold'>THE STORY</h1>
                        <div className='text-base mt-2'>
                            You might be reading this with a cup of coffee in your hand and enjoying the warmth of the
                            coffee on your palm and that mesmerizing taste on your taste buds. But what if this cozy warmth
                            turns out to be the reason of your finger burn. <br />
                            This is what happed with Mr. Jay Sorenson from Portland, Oregon, once while having his coffee
                            on the way to his daughter's school, he spilled the coffee and burned his fingers. This burning of
                            finger flashed a thought in Jay's mind, that every morning one or another might be going
                            through hard holding a hot cup of Coffee/Tea and this made him do something and two years
                            later, he came up with JAVA JACKETS, a coffee cup sleeve brand that cuddles your coffee
                            making you enjoy the sip without thinking of any finger burns or spills.</div>

                    </div>

                    <div className='pt-5 py-2'>
                        <h1 className='text-2xl font-bold'>FIRST KICK</h1>
                    </div>
                    <div className='pt-5'>
                        <div className='text-base'>
                            Java Jackets wasn't a polished diamond that came from nowhere and achieved success.
                            Actually, Jay first came up with the idea of making Insulated cups to eliminate the access heat
                            from the cup. But this wasn't economical because not all coffees need insulation, lattes and cold
                            coffee fall apart from the usage of the insulated cups also it was an issue for him to produce
                            such cup and make it available in mass quantity. So he rejected this idea.
                        </div>
                    </div>

                    <div className='pt-5 py-2'>
                        <h1 className='text-2xl font-bold'>GOAL KICK</h1>
                    </div>
                    <div className='pt-5'>
                        <div className='text-base'>
                            Jay in an interview said that," he isn't sure how he came up with an idea of a cardboard sleeve
                            around the coffee mug", he said," It was kind of an evolution.". And this way Java Jackets came
                            into picture. Jay used embossed cardboard papers to make sleeves, it was easy to produce and
                            recycle, making it a sustainable solution too. He tried his luck in the local Coffee stores and his
                            idea generally got a thrust, when he sold 100 cases of Java Jacket in less than half an hour. This
                            gave Jay, plenty of insight on how he can escalate his business into the local market as well as
                            other markets. In the initial year Java Jackets was sold over to around 500 clients in the local
                            cities. In the present day about 100 billion java Jackets are sold every year world-wide. Now every
                            major coffee brand uses this innovative idea of having sleeve of cardboard wrapped around
                            their hot coffee cups. This not only gives customer comfort while holding their Coffee mugs but
                            also provide the sellers a good way to treat their customers with their name on the Sleeves.
                        </div>
                        <div>
                            <img src={Ecell4_1} className="center m-2" width="85%" height="85%"></img>
                        </div>
                    </div>



                    <div className='pt-5 py-2'>
                        <h1 className='text-2xl font-bold'>THE LESSON</h1>
                    </div>
                    <div className='pt-5'>
                        <div className='text-base'>
                            This is a simple solution of the problem that everyone faces in their daily life, but Sorenson
                            thought about having something to eliminate it and he came up with this innovative idea which
                            turned into a million-dollar company. Innovation can be done in any way, but only with a mind
                            who is ready to bring the solution, keeping all the odds aside. With some wise planning and will
                            to execute the plan one can succeed crossing all the limits.
                        </div>
                    </div>
                    <div className='mt-5'>
                    <img src={Ecell4_2} className="center m-2" width="85%" height="85%"></img>
                    </div>
                </div>
                <Share_Blog />
            </div>
        </>
    )
}
